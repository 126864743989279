import React, { useState } from "react";
import { Col, Row, Label } from "reactstrap";


const Documents = () => {
const [companyGst,setCompanygst]=useState('Upload File( Max size 5MB)')
const [companyPan,setCompanypan]=useState('Upload File( Max size 5MB)')
const [cheque,SetCheque]=useState('Upload File( Max size 5MB)')
const [companyLogo,setCompanylogo]=useState('Upload image File( Max. Resolution:512*512)')
const [authorizesPan,SetAuthorizepan]=useState('Upload File( Max size 5MB)')
const [certification,setCertification]=useState('Upload File( Max size 5MB)')

    return (
        <>
            <Row style={{ padding: '15px' }} >
                <div  style={{  fontWeight: '500' }}>Business Documents</div>
                <Label className="mt-3">Company GST</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{companyGst}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile01" onChange={(e) => { setCompanygst(e.target.files[0].name) }}/>
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile01">Browser</label>
                </Col>
              </div> 
              <Label className="mt-3">Company PAN</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{companyPan}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile02" onChange={(e) => { setCompanypan(e.target.files[0].name) }} />
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile02">Browser</label>
                </Col>
              </div> 
              <Label className="mt-3">Cancelled Cheque</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{cheque}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile03" onChange={(e) => { SetCheque(e.target.files[0].name) }} />
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile03">Browser</label>
                </Col>
              </div> 
              <Label className="mt-3">Company Logo</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{companyLogo}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile04" onChange={(e) => { setCompanylogo(e.target.files[0].name) }} />
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile04">Browser</label>
                </Col>
              </div> 
              <Label className="mt-3">Authorized Person PAN</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{authorizesPan}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile05" onChange={(e) => { SetAuthorizepan(e.target.files[0].name) }} />
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile05">Browser</label>
                </Col>
              </div> 
              <Label className="mt-3">Certification of Incorporation (COI)</Label>
                <div className="input-group ">
                <Col lg={10} md={9} sm={7} xs={8} style={{ border: '1px solid #8080809e', borderRight: 'none',borderRadius:'5px' }}>
                  <span className="m-2" style={{fontSize:'13px',color:'#585858'}}>{certification}</span> 
                </Col>
                <Col lg={2} md={3} sm={5} xs={4} style={{ textAlign: "center" }}>
                <input type="file" className=" d-none form-control" id="inputGroupFile06" onChange={(e) => { setCertification(e.target.files[0].name) }} />
                  <label className="input-group-text " style={{ background: '#fff', border: '1px solid #8080809e',cursor:'pointer'}}  htmlFor="inputGroupFile06">Browser</label>
                </Col>
              </div> 
            </Row>

        </>
    );
};

export default Documents;

