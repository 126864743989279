
import React, { useState,useEffect,useMemo} from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "./Admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate ,Link} from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { FaTrashAlt, FaEdit } from "react-icons/fa";

const SuperDist = () => {
  const [superdistList, setsuperDistList] = useState([]);
  let navigate=useNavigate()
 
  useEffect(() => {
    superDistList()
  }, []);

  const superDistList = () => {

    let url = process.env.REACT_APP_BASEURL + "v1/asset/super_dist/"
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')
      }
    };

    axios.get(url, config)
      .then(res => {
        console.log("fghgfj", res.data)
        setsuperDistList(res.data.data);
      }
      ).catch(err => {

      })
  }

  return (
    <>
      <Row>
        <Col  className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <CiMobile4 /> Superdist
          </h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header  className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{  fontWeight: 'bold' }}> Superdist</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3 ">
                <Col xs="6">
                  <Input
                   name="search"
                    type="text"
                    placeholder="Search by Name"
                  />
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                <Link to="/addsuperdist"><Button  className="header-color" style={{border:'none'}}>+ Add </Button></Link>  

                </Col>
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    {/* <th>Profile</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {superdistList.map((superdist, index) => {
                    return (<tr>
                      <td>{superdist.name}</td>
                      <td>{superdist.username}</td>
                      <td>{superdist.phone_number}</td>
                      {/* <td>{superdist.avatar}</td> */}
                      <td>
                      <FaEdit className="ms-2 " style={{color:'rgb(39 104 182'}} onClick={()=>navigate(`/updatesuperdist/${superdist.eid}`)}></FaEdit>

                        {/* <FaTrashAlt className="ms-3 text-danger" ></FaTrashAlt> */}
                      </td>
                    </tr>)
                  })}
                </tbody>

              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SuperDist;

