import React, { useState } from "react";
import { Col, Row, Button,  Input } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { FiSend } from "react-icons/fi";
import Form from 'react-bootstrap/Form';
import './transfer.css'


function SingleTransfer() {
    let [formData, setFormData] = useState({
        account: '',
        payee: '', 
        amount: '', 
        purpose: '',
    });
    let [errorMessage , setErrorMessage ]= useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
       
        if(!formData.account){
           setErrorMessage('Account Name is required.')
        }else if(!formData.payee){
           setErrorMessage('Payee Name is required.')
        }else if(!formData.amount){
           setErrorMessage('Amount Name is required.')
        }else{
           setErrorMessage('')  
        }
        setLoading(true)
        if (!errorMessage) {
            console.log("formData",formData);
            // const getlocalStorage = JSON.parse( localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY))

            // const config = {
            //     headers: {
            //         // "Access-Control-Allow-Origin": "*",
            //         // "Content-Type": "plain/text",
            //         // "Accept" : "plain/text",
            //         'Authorization' : 'Bearer ' + getlocalStorage.result.token,
            //         "x-api-key": process.env.REACT_APP_X_API_KEY,
            //     },
            // };
            // let body =  ({
            //     name: formData.accountName,
            //     email: formData.email,
            //     purpose: formData.purpose,
            //     startDate: formData.startDate,
            //     validity: formData.validity,
            //     expectedVolume: formData.expectedVolume,
            //     bank: "icici",
            //     documentIds: [
            //     "DC9039302951"
            //     ]
            //     })
 
            // let url = process.env.REACT_APP_BASEURL + "/api/v1/account"         
        
            // axios.post(url, body, config)
            //     .then(res => {
            //         setLoading(false);
            //         console.log("res", JSON.stringify(res));
                   
            //     }).catch(error => {
            //         console.log("ERRRR",error);
            //         setLoading(false);
            //         if (error) {
            //             const getErrRes = errorHandler(error)
            //             // const getErrRes = ''
            //             if (getErrRes === 401) {
            //                 navigate("/");
            //             } else {
            //                 toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                     autoClose: 3000,
            //                     theme: "colored",
            //                 });
            //             }
            //         }
            //     })
        } else {
            setLoading(false);
        }
    };


    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h2 className="text-white"> <FiSend />  Transfer</h2>
                    <span className="text-white">Create a new Transfer Entry </span>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
                            <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                                <div style={{ marginLeft: '20px' }}> Fund Transfer</div>

                            </div>
                        </Card.Header>
                        <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            {errorMessage && 
                                <Col className="mt-1 " >
                                    <div className="alert alert-danger d-flex justify-content-between" role="alert">
                                            <div>  {errorMessage}</div>
                                      <div>
                                                <Button onClick={() => setErrorMessage('')} type="submit" className="close left " style={{background:'transparent',border:'none'}} aria-label="Close">
                                                    <span aria-hidden="true" style={{fontSize:'18px',fontWeight:'bold',color:'red'}}>&times;</span>
                                                </Button>
                                      </div>
                                        
                                    </div>
                                </Col>
                            }

                            <Col className="mt-1" >
                                <label>Account</label>
                                <Input name="account_name" type="text" placeholder="Enter Account Name" className='mt-2 pt-1 pb-1 pr-2 '
                                    onChange={(e) => { setFormData({ ...formData, account: e.target.value, })  }}
                                />
                            </Col>
                            <Col className="mt-3"  >
                                <label>Payee</label>
                                <Input name="payee" type="text" placeholder="Enter Payee Name" className='mt-2 pt-1 pb-1 pr-2' 
                                 onChange={(e) => { setFormData({ ...formData, payee: e.target.value, })  }}
                                />
                            </Col>
                            <Col className="mt-3"  >
                                <label>Amount</label>
                                <Input name="amount" type="number" placeholder="Enter Amount" className='mt-2 pt-1 pb-1 pr-2'
                                    onChange={(e) => { setFormData({ ...formData, amount: e.target.value, })  }}
                                />
                            </Col>
                            <Col className="mt-3"  >
                                <label>Purpose (optinal)</label>
                                <Input name="transfer" type="number" placeholder="Purpose of the transfer" className='mt-2 pt-1 pb-1 pr-2' 
                                   onChange={(e) => { setFormData({ ...formData, purpose: e.target.value, })  }}
                                   />
                            </Col>
                            <Row className="mt-4">
                                <Col>
                                    <span style={{ fontWeight: 'bold', color: '#4e4b4b' }}>Notes</span> (optional-Max 15 key-Value) <Button type="submit" style={{ background: '#3085b042', border: 'none', color: 'black', padding: '8px', borderRadius: '0px', fontSize: '15px' }}>+ Add New</Button>
                                </Col>
                            </Row>

                            <div className="mt-3">
                                <Input name="account_name" type="checkbox" placeholder="Enter Account Name" /> <span>Add Supporting Documents</span>

                            </div>
                            <div className="mt-2" >
                                <span > (Acceptable file formats- .png, .jpg, .xlsx, .csv, .doc, .docx, Max size - 5 MB Multiple files can be uploaded)</span>
                            </div>
                            <div className="mt-3 " >
                                <span className="fw-bold">Additional Transfer Request</span>

                            </div>
                            <Row className="m-2"><hr></hr></Row>

                            <div className="ms-2">
                                Add Recurring Transfer<br></br>
                                <Form.Check type="switch" id="custom-switch" />
                            </div>
                            <div className="mt-3">
                                <Input name="account_name" type="checkbox" placeholder="Enter Account Name" /> <span>I agree to the </span> <span style={{ color: '#0149AD', fontWeight: '500' }}>Terms and conditon</span>

                            </div>
                            <Row className="mt-4">
                                <Col>
                                    <Button type="submit" className="header-color" style={{  border: 'none' }}>Request Transfer</Button>
                                </Col>
                            </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default SingleTransfer;