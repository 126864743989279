import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Col, Row } from "reactstrap";
import Card from 'react-bootstrap/Card';
import SingleTransferTab from "./SingleTransferTab";
import BulkTransferTab from "./BulkTransferPage";
import ScheduledTransferTab from "./ScheduledTransfer";
import { TbActivityHeartbeat } from "react-icons/tb"

const FundTransferTabs = () => {
    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}><TbActivityHeartbeat /> Transfer Report</h3>
                    <span className="text-white">Transfer Detail </span>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Body style={{ padding: '0px' }}>
                            <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="subheader-color" style={{ marginTop: '-15px', }}>
                                <Tab eventKey="description" title="Single Transfer"><SingleTransferTab/></Tab>
                                <Tab eventKey="linkpayee" title="Bulk Transfer"><BulkTransferTab/></Tab>
                                <Tab eventKey="documents" title="Scheduled Transfers" ><ScheduledTransferTab/></Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </>
    );
}

export default FundTransferTabs;