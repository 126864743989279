import React, { useState } from "react";
import { Col, Input, Button, Form } from "reactstrap";
import Card from 'react-bootstrap/Card';
import Logo from "../../assets/images/logos/escrowlogo.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { toast } from 'react-toastify';
import { errorHandler } from "../../utils/ErrorHandler";

function Login() {
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [otpSend, setOtpSend] = useState(false)
    let [addData, setAddData] = useState({
        email: '', emailerr: '',
        password: '', passworderr: '',
        otp: '', otperr: '',
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        let error = { status: 0, password: '', email: '', otp: '' };
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (addData.password === '') { error.password = 'Please enter password.'; error.status = 1 } else { error.password = '' }
        if (addData.email <= 0 || addData.email === '') { error.email = 'Please enter email.'; error.status = 1 }
        else if (!filter.test(addData.email)) {
            error.email = 'Please enter a valid email address.'; error.status = 1
        } else { error.email = '' }

        if (addData.otp === '' && otpSend) { error.otp = 'Please enter otp.'; error.status = 1 } else { error.otp = '' }

        setAddData({ ...addData, passworderr: error.password, emailerr: error.email, otperr: error.otp });
        if (error.status === 0) {
            let user = {
                email: addData.email,
                password: addData.password,
                otp: addData.otp
            }
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    // "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };
            // let body =  {
            //     'apiKey': process.env.REACT_APP_API_KEY,
            //     'apiSecret': process.env.REACT_APP_API_SECRET_KEY,
            // }

            // let url = process.env.REACT_APP_BASEURL + ""
            let url = process.env.REACT_APP_BASEURL +'v1/auth/login/'
            // var encodedStringBtoA = btoa(`${user.email} ${user.password}`);
            // let sendBody = {
            //     'credentials': encodedStringBtoA,
            // }
            // if (otpSend) {
            //     sendBody.otp = user.otp
            // }
         

            var data = new FormData();
            data.append('email', user.email);
            data.append('password ', user.password,);


            axios.post(url, data, config)
                .then(res => {
                    console.log("res",res)
                    
                    localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_KEY, res.data.data.access_token)
                    navigate("/dashboard");

                    setLoading(false);
                    // res.data.success = true
                    // if (res.data.success) {
                    //     if (res.data.result?.otpSend) {
                    //         toast.success(res.data.message, {
                    //             position: toast.POSITION.TOP_RIGHT,
                    //             autoClose: 3000,
                    //             theme: "colored",
                    //         });
                    //         setOtpSend(true)
                    //     } else {
                    //         // const data = JSON.stringify({"success":true,"errors":[],"result":{"roles":["admin"],"active":true,"merchantUserId":"MU6892775232","merchant":{"merchantId":"MR3092646965","title":"Laetitia Finserv Private Limited","verified":true},"email":"laetitiafinserv@gmail.com","user":{"loginOTPEnabled":true,"firstName":"Laetitia","lastName":"Finserv","mobile":9776961449,"email":"laetitiafinserv@gmail.com"},"merchantProfile":{},"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2ExNjIyNzVhZTdkNTAwMTljNDkxYTciLCJyb2xlcyI6WyJhZG1pbiJdLCJtZXJjaGFudFVzZXJJZCI6Ik1VNjg5Mjc3NTIzMiIsImFwaUNyZWRlbnRpYWxzUHJvdmlkZWQiOnRydWUsImlhdCI6MTY3MTUzNzIwOSwiZXhwIjoxNjcxNjIzNjA5fQ.tVSdlXluiT3aRYzLldvpSLkycRY7UtSW-sJ3FEoJxVw","image":"/uploads/profiles/default.jpeg"}})
                    //         // localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_KEY, data)
                    //         res = JSON.stringify({ result: { "roles": ["admin"], "active": true, "merchantUserId": "MU6892775232", "merchant": { "merchantId": "MR3092646965", "title": "Laetitia Finserv Private Limited", "verified": true }, "email": "laetitiafinserv@gmail.com", "user": { "loginOTPEnabled": true, "firstName": "Laetitia", "lastName": "Finserv", "mobile": 9776961449, "email": "laetitiafinserv@gmail.com" }, "merchantProfile": {}, "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2ExNjIyNzVhZTdkNTAwMTljNDkxYTciLCJyb2xlcyI6WyJhZG1pbiJdLCJtZXJjaGFudFVzZXJJZCI6Ik1VNjg5Mjc3NTIzMiIsImFwaUNyZWRlbnRpYWxzUHJvdmlkZWQiOnRydWUsImlhdCI6MTY3MTU0MTc1MiwiZXhwIjoxNjcxNjI4MTUyfQ.9ytrq5Mtyfjw0gBX2wxQCOdXx6hjNmWO8wxAQ_eLtlE", "image": "/uploads/profiles/default.jpeg" } })
                    //         localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_KEY, JSON.stringify(res.result))
                    //         setOtpSend(false)
                    //         navigate("/dashboard");
                    //     }
                    // } else {
                    //     toast.error(res.data.errors[0] ? res.data.errors[0] : 'Something went wrong.', {
                    //         position: toast.POSITION.TOP_RIGHT,
                    //         autoClose: 3000,
                    //         theme: "colored",
                    //     });
                    // }
                }).catch(error => {
                    // localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_KEY, JSON.stringify('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2ExNjIyNzVhZTdkNTAwMTljNDkxYTciLCJyb2xlcyI6WyJhZG1pbiJdLCJtZXJjaGFudFVzZXJJZCI6Ik1VNjg5Mjc3NTIzMiIsImFwaUNyZWRlbnRpYWxzUHJvdmlkZWQiOnRydWUsImlhdCI6MTY3MTU0MTc1MiwiZXhwIjoxNjcxNjI4MTUyfQ.9ytrq5Mtyfjw0gBX2wxQCOdXx6hjNmWO8wxAQ_eLtlE'))
                    setLoading(false);
                    // navigate("/dashboard");
                    // toast.error('somthing went wrong', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 3000,
                    //     theme: "colored",
                    // });
                    if (error) {
                        const getErrRes = errorHandler(error)
                        if (getErrRes === 401) {
                            navigate("/");
                        } else {
                            toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                                theme: "colored",
                            });
                        }
                    }
                })
        } else {
            setLoading(false);
        }
    };
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Col style={{ minHeight: '90%' }} >
                <div className="d-flex justify-content-center">
                    <Col className="mt-5" lg={4} sm={5} md={6}>
                        <Card  style={{ borderRadius: '10px', boxShadow: '3px 3px 3px 3px #ECECEC' }}>
                            <Card.Header className="d-flex justify-content-center p-3 subheader-color" >
                                <img src={Logo} width='200' height='52' alt="logo"></img>
                            </Card.Header>
                            <Form onSubmit={handleSubmit}>
                                <Card.Body style={{ padding: '30px', borderTop: '1px solid #dddfe2', borderBottom: '1px solid #dddfe2' }}>
                                    <Col>
                                        <label>Email</label>
                                        <Input disabled={otpSend} type="email" style={{ marginBottom: '10px', marginTop: '6px', padding: '10px' }}
                                            placeholder='Enter email address'
                                            defaultValue={addData.email}
                                            onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'Email  is required.' }) }}
                                        ></Input>
                                        <small className="text-danger">{addData.emailerr}</small>
                                    </Col>
                                    <Col>
                                        <label>Password <span style={{ color: '#0149AD', fontWeight: 'bold' }}> (Forgot)?</span> </label>
                                        <Input disabled={otpSend} type="password" style={{ marginBottom: '10px', padding: '10px', marginTop: '6px' }}
                                            placeholder='Enter Password'
                                            defaultValue={addData.password}
                                            onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, password: e.target.value, passworderr: '' }) : setAddData({ ...addData, password: '', passworderr: 'Password  is required.' }) }}
                                        ></Input>
                                        <small className="text-danger">{addData.passworderr}</small>
                                    </Col>

                                    {
                                        otpSend &&
                                        <Col>
                                            <label>OTP</label>
                                            <Input type="number" style={{ marginBottom: '10px', marginTop: '6px', padding: '10px' }}
                                                placeholder='Enter OTP'
                                                defaultValue={addData.otp}
                                                onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, otp: e.target.value, otperr: '' }) : setAddData({ ...addData, otp: '', otperr: 'Otp  is required.' }) }}
                                            ></Input>
                                            <small className="text-danger">{addData.otperr}</small>
                                        </Col>
                                    }
                                    <div className="d-flex justify-content-center mt-3">
                                        {loading ?
                                            <Button type="submit" className="col-12 p-2" style={{ background: '#23528b', border: 'none' }} disabled>Login...
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            </Button> :
                                            <Button type="submit" className="col-12 p-2" style={{ background: '#23528b', border: 'none' }}>Login</Button>
                                        }
                                    </div>
                                </Card.Body>
                            </Form>

                            <Card.Footer className="d-flex justify-content-center p-3" >

                            </Card.Footer>
                        </Card>
                    </Col>
                </div>
            </Col>
            <div className="d-flex justify-content-between align-items-end text-white  ">
                <div style={{ marginLeft: '20px' }}>
                    Copyright © Castler 2021
                </div>
                <div style={{ marginRight: '20px' }}>
                    Privacy Policy · Terms & Conditions
                </div>
            </div>
        </div>
    )
}
export default Login