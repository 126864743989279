const ChallanListData = () => {


    return [
        {
            accountId: "PPAY93839899",
            payerName: "Rohit Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "ABC Private Limited",
            status: "pending",
          },
          {
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },
          {
            accountId: "PPAY93839899",
            payerName: "Rohit Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "ABC Private Limited",
            status: "pending",
          },
          {
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },
          ,{
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Ritesh Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "YTS Private Limited",
            status: "pending",
          },{
            accountId: "PPAY93839899",
            payerName: "Aman Singh",
            payerDetail1: "Some address for sunil",
            payerDetail2: "Some address for anil",
            contact1: "Contact number",
            contact2: "Contact number",
            amount: 20,
            amountWords: "Twenty Rupees",
            challanId: "CH93893894",
            createdAt: "2020-12-07T06:35:14.407Z",
            merchantName: "VIB Private Limited",
            status: "pending",
          },
    ]
}

export { ChallanListData }