import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { FiSend } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import axios from "axios";
import CustomPagination from "../Pagination/CustomPagination";
import { ChallanListData } from "./ChallanListData";

const ChallanList = () => {
  let PageSize = 10;

  let [challandataList, setChallanData] = useState(ChallanListData());

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = () => {
    if (searchValue) {
      getChallanList(searchValue);
    }
  };

  useEffect(() => {
    getChallanList("");
  }, []);

  const getChallanList = (searchParameters) => {
    if (searchParameters) {
      var parmas = "?page=" + currentPage + "&_project=" + searchParameters;
    } else {
      parmas = "?page=" + currentPage;
    }

    let url = process.env.REACT_APP_BASEURL + "/api/v1/challan" + parmas;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        // console.log(res.data);
        setChallanData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useMemo(() => {
    getChallanList(searchValue);
  }, [currentPage]);

  return (
    <>
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            <FiSend></FiSend> Challans
          </h3>
          <h5 className="text-white ">View all Challan</h5>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header className="subheader-color" style={{ backgroundColor: "", padding: "15px" }}>
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD" }}
              >
                <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  Challans List
                </div>
                <Button
                  style={{
                    backgroundColor: "#0149AD",
                    borderRadius: "50%",
                    borderColor: "#0149AD",
                  }}
                >
                  <FiRefreshCw />{" "}
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3">
                <Col sm="10">
                  <Input
                    name="search"
                    type="text"
                    placeholder="Search by Payee Name"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </Col>
                <Col sm="2">
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "5px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    onClick={() => {
                      setSearchValue("");
                      getChallanList("");
                    }}
                  >
                    {" "}
                    clear
                  </span>
                </Col>
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>URN</th>
                    <th>Account Number</th>
                    <th>Creation Date</th>
                    <th>Payer Name</th>
                    <th>Contact 1</th>
                    <th>Amount </th>
                    <th>status </th>
                    <th>Challan</th>
                  </tr>
                </thead>
                <tbody>
                  {challandataList?.length > 0 &&
                    challandataList.map((item, index) => {
                      return (
                        <tr key={index} className="border-top">
                          <td>{item.merchantName}</td>
                          <td>{item.accountId}</td>
                          <td>{item.createdAt}</td>
                          <td>{item.payerName}</td>
                          <td>{item.contact1}</td>
                          <td>{item.amount}</td>
                          <td>{item.status}</td>
                          <td>{item.challanId}</td>
                        </tr>
                      );
                    })}

                  {challandataList.length === 0 && <h4>No Data Available</h4>}
                </tbody>

                <CustomPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={challandataList.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChallanList;
