import React from "react";
import { Col, Row } from "reactstrap";
import './interactiveDashboard.css';
import Card from 'react-bootstrap/Card';
import Chart from 'react-apexcharts';
import ProgressBar from "@ramonak/react-progress-bar";
// import ChartDetail from './Chart'
function InteractiveDashboard() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();



    return (
        <>
            <div className="bg-interactive p-5">
                <h2 className="text-white">Energy Dashboard</h2>
                <div>
                    <Row className="text-white mt-5 d-flex justify-content-between " >
                        <Col className="current-time-block d-flex justify-content-between" lg={10} >
                            <div> Today</div>
                            <div> Month</div>
                            <div> Year</div>

                        </Col>
                        <Col lg={2} >
                            <Col style={{ color: 'white' }}>{`${month === 1 ? 'january' : month === 2 ? 'Februray' : month === 3 ? 'March' : month === 4 ? 'april' : month === 5 ? 'May' : month === 6 ? 'june' : month === 7 ? 'July' : month === 8 ? 'august' : month === 9 ? 'September' : month === 10 ? 'October' : month === 11 ? 'November' : month === 12 ? 'December' : null}, ${day},${year}`}</Col>
                        </Col>
                    </Row>

                </div>
                <Row className="mt-5">
                    <Col>
                        <Card

                            style={{ background: 'rgba(29,27,65,.6)', height: '380px' }}
                            className="mb-2 p-0"
                        >
                            <Card.Header style={{ color: 'white', marginTop: '10px' }}>COST PREDICTED</Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body className='pe-0 ps-0 ' style={{ color: 'white', marginTop: '50px' }}>
                                <Chart
                                    
                                    type='donut'
                                    width={310}
                                    height={320}

                                    series={[45, 67]}
                                    options={{
                                        labels: ['Ekectricity', 'Gas'],
                                     

                                        colors: ['#f7e53b', '#58e2c2'],
                                        fill: { opacity: 5 },
                                        plotOptions: {

                                            pie: {
                                                donut: {
                                                    labels: {
                                                        show: true,
                                                //   color:'whtie',
                                                        total: {
                                                            show: true,
                                                        color: 'white',
                                                        },
                                                        fill:{
                                                            colors:['white']
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false,
                                            style: {
                                                colors: ['#fff']
                                            }
                                   
                                        },
                            
                                        

                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card

                            style={{ background: 'rgba(29,27,65,.6)', height: '380px' }}
                            className="mb-2"
                        >
                            <Card.Header style={{ color: 'white', marginTop: '10px' }}>CHANGE IN COST</Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body className='pe-0 ps-0'>
                                <Chart
                                    type='bar'

                                    height={250}
                                    series={
                                        [
                                            {
                                                name: 'Visible Light Graph',
                                                data: [203, 214]
                                            }

                                        ]
                                    }
                                    toolbar={false}
                                    options={

                                        {

                                            title: {

                                                style: { fontSize: 20 }
                                            },

                                            colors: ['#3d9293', ' #3d91937'],
                                            stroke: { width: 1, curve: 'smooth' },
                                            fill: { opacity: 5 },

                                            xaxis: {
                                              
                                                categories: ['Dec', 'Jan'],
                                                labels: {
                                                    style: { colors: ['#fff', '#fff'] }
                                                }

                                            },

                                            yaxis: {

                                                title: {
                                                    text: 'Value',
                                                    style: { fontSize: 20 }
                                                },
                                                labels: {
                                                    style: { colors: ['#fff', '#fff'] }
                                                }
                                            },
                                           




                                        }
                                    }>

                                </Chart>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card

                            style={{ background: 'rgba(29,27,65,.6)', height: '380px' }}
                            className="mb-2"
                        >
                            <Card.Header style={{ color: 'white', marginTop: '10px' }}>USAGE ESTIMATE</Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body>
                                <Chart
                                    type='area'

                                    height={250}
                                    series={
                                        [
                                            {
                                                name: 'Visible Light Graph',
                                                data: [0, 1, 0.75, 0.25, 0.5, 0.2]
                                            }

                                        ]
                                    }
                                    options={
                                        {
                                            title: {

                                                style: { fontSize: 20 }
                                            },
                                            colors: ['#d73e71'],
                                            stroke: { width: 1, curve: 'smooth' },
                                            fill: { opacity: 5 },
                                            

                                            xaxis: {
                                               
                                                categories: [0, 4, 8, 12, 16, 20],
                                                labels: {
                                                    style: { colors: ['#fff', '#fff', "#fff", '#fff', "#fff",'#fff'] }
                                                }
                                    

                                            },

                                            yaxis: {

                                                title: {
                                                    text: 'Value',
                                                    style: { fontSize: 20 }
                                                },
                                                labels: {
                                                    style: { colors: ['#fff', '#fff', "#fff", '#fff', "#fff", '#fff'] }
                                                }

                                            },
                                           


                                        }
                                    }>

                                </Chart>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Card

                            style={{ height: '380px', background: 'rgba(29,27,65,.6)' }}
                            className="mb-2"
                        >
                            <Card.Header style={{ color: 'white' }}>ACTIVE APPLIANCE</Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body style={{ marginTop: '-50px' }}>
                                <Card.Title> Card Title </Card.Title>
                                <Chart
                                    type='bar'

                                    height={320}
                                    series={
                                        [
                                            {
                                                name: 'Visible Light Graph',
                                                data: [1.4, 0.9, 0.8, 0.7, 0.4, 0.2]
                                            }

                                        ]
                                    }
                                    toolbar={false}
                                    options={

                                        {
                                            plotOptions: {
                                                bar: {
                                                    borderRadius: 4,
                                                    horizontal: true,
                                                }
                                            },
                                     
                                            title: {

                                                style: { fontSize: 20 }
                                            },


                                            stroke: { width: 1, curve: 'smooth' },
                                            fill: { opacity: 5 },

                                            xaxis: {

                                                categories: ['Heating & AC', 'EV Charge', 'Plug Loads', 'Refrigeration'],
                                                labels: {
                                                    style: { colors: ['#fff'] }
                                                }


                                            },

                                            yaxis: {
                                                labels: {
                                                 style:{colors:['#fff'],
                                                 fontSize:12}
                                                }

                                            },

                                            fill: {
                                                type: 'gradient',
                                                colors: ['#ab26c2', ' #9326bf', "#7625b9", "#5e24b6", '#4424b1', '#3123ae'],
                                                gradient: {

                                                    shadeIntensity: 0.4,
                                                    inverseColors: false,
                                                    opacityFrom: 1,
                                                    opacityTo: 1,
                                                    stops: [0, 10, 10, 55, 60]
                                                },
                                            },




                                        }
                                    }>

                                </Chart>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card

                            style={{ background: 'rgba(29,27,65,.6)', height: '380px', }}
                            className="mb-2"
                        >
                            <Card.Header style={{ color: 'white', marginTop: '10px' }}>ENERGY INTENSITY</Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body>
                                <Chart
                                    type='radialBar'

                                    height={400}
                                    series={
                                        [76]
                                    }


                                    options={{


                                        offsetY: -20,

                                        sparkline: {
                                            enabled: true
                                        },
                                        plotOptions: {
                                            radialBar: {
                                                startAngle: -90,
                                                endAngle: 90,

                                                track: {
                                                    background: "#49516f",
                                                    strokeWidth: '97%',
                                                    margin: 5, // margin is in pixels
                                                    // dropShadow: {
                                                    //     enabled: true,
                                                    //     top: 2,
                                                    //     left: 0,
                                                    //     color: '#999',
                                                    //     opacity: 1,
                                                    //     blur: 2
                                                    // }
                                                },
                                                dataLabels: {
                                                    name: {
                                                        show: false
                                                    },
                                                    value: {
                                                        offsetY: -2,
                                                        fontSize: '30px',
                                                        color: '#fff'
                                                    }
                                                }
                                            }
                                        },
                                        grid: {
                                            padding: {
                                                top: -10
                                            }
                                        },
                                        fill: {
                                            type: 'gradient',
                                            colors: ['#59e2c2'],
                                            gradient: {
                                                shade: 'light',
                                                shadeIntensity: 0.4,
                                                inverseColors: false,
                                                opacityFrom: 1,
                                                opacityTo: 1,
                                                stops: [0, 50, 53, 91]
                                            },
                                        },
                                        labels: ['Average Results'],

                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card

                            style={{
                                background: 'rgba(29,27,65,.6)', height: '380px' }}
                            className="mb-2"
                        >
                            <Card.Header style={{ color: 'white', marginTop: '10px' }}>CARBON FOOTPRINT </Card.Header>
                            <hr style={{ border: '2px solid rgb(4 20 45)' }}></hr>
                            <Card.Body>
                            <span className="text-white fw-bold">Emission</span><br></br>
                            <div className="d-flex justify-content-between mt-2">
                                    <span className="text-white fw-lighter " style={{ fontSize: '12px' }}>Till Date</span>
                                    <span className="text-white fw-lighter " style={{ fontSize: '12px' }}>Predicted</span>
                            </div>
                                
                                <ProgressBar completed={30} bgColor='#8d8d8d' baseBgColor='#495370' borderRadius='1px' labelColor='transparent'  height="15px" />
                                <div className="d-flex justify-content-between">
                                    <span className="text-white fw-bold " style={{ fontSize: '14px' }}>36.4 Kg of CO2 </span>
                                    <span className="text-white fw-bold " style={{ fontSize: '14px' }}>181.9 Kg of CO2 </span>
                                </div>

                                <div className="mt-5">
                                    <span className="text-white fw-bold">Green Energy Genrated</span><br></br>
                                    <div className="d-flex justify-content-between">
                                        <span className="text-white fw-lighter " style={{ fontSize: '12px' }}></span>
                                        <span className="text-white fw-lighter " style={{ fontSize: '12px' }}>Goal</span>
                                    </div>

                                    <ProgressBar completed={60} bgColor='#93c360' baseBgColor='#495370' borderRadius='1px' labelColor='transparent' height="15px" />
                                    <div className="d-flex justify-content-between">
                                        <span className="text-white fw-bold " style={{ fontSize: '14px' }}>21.20 KWh </span>
                                        <span className="text-white fw-bold " style={{ fontSize: '14px' }}>50kWh </span>
                                    </div> 
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    )

}
export default InteractiveDashboard;