import React from "react";
import { Col, Row, Button, FormGroup, Form, Label, Input } from "reactstrap";

const PasswordReset = () => {

    return (
        <>

            <Row style={{ marginTop: '20px',padding:'15px' }} >
                <Col lg={12}>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="examplePassword">Current Password</Label>
                            <Input id="examplePassword" name="currentpwd" placeholder="Current Password" type="text" />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="password">New Password</Label>
                            <Input id="password" name="newpwd" placeholder="New Password" type="text" />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="pdetails1">Confirm Password</Label>
                            <Input id="pdetails1" name="confirmpwd" placeholder="New Password" type="text" />
                        </FormGroup>
                        <div className="button-group">
                            <Button className="btn header-color" style={{  borderColor: '#0149AD' }}>
                                Update
                            </Button>
                        </div>
                    </Form>


                </Col>
            </Row>
        </>
    );
};

export default PasswordReset;
