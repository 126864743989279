const BulktransFerListData = () => {


    return [
        {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },{
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },{
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },{
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },{
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },{
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "ABC Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "true",
          },
          {
            status: "Pending for Authorization",
            batchId: "BT3332146336",
            batchName: "test",
            accountId: "PPAY3978796523",
            accountBalance: 64,
            totalAmount: 34444,
            merchantTitle: "XYZ Private Limited",
            createdAt: "2020-12-07T06:35:14.407Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            active: "false",
          },
    ]
}

export { BulktransFerListData }