import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FiUser } from "react-icons/fi"
import { Col, Row } from "reactstrap";
import Card from 'react-bootstrap/Card';
import BussinessProfile from "./BusinessProfile";
import Documents from "./Documents";

const BussinessTabs = () => {
    return (
        <>
            <Row>
                <Col className="header-color" style={{padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "500" }}><FiUser/>Business Profile </h3>
                    <span className='text-white' >Basic Information of User</span>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Body  style={{  padding: '0px' }}>
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="subheader-color"  style={{marginTop:'-15px',}}>
                                <Tab eventKey="profile" title="Profile"><BussinessProfile/></Tab>
                                <Tab eventKey="documents" title="Documents"><Documents/></Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
          
        </>
    );
}

export default BussinessTabs;