import React from "react";
import {Row, Button, } from "reactstrap";
const FactorAuthentication = () => {

    return (
        <>
            <Row style={{ padding: '15px', }} >
                <div className="d-flex justify-content-around ">
                    <div>
                        <div style={{ marginLeft: '20px', fontWeight: '500', textAlign: 'center' }}>Google Authenticator</div>
                        <div style={{ marginTop: '20px', fontSize: '14px', textAlign: 'center' }}> Add an extra layer of security to your
                            accountby using google Authenticator<br></br>
                            code in addition to your password each time you log in</div>
                        <div style={{ marginTop: '30px', fontSize: '13px', textAlign: 'center' }}>
                            <Button className="btn" style={{ backgroundColor: '#23528b', borderColor: '#0149AD', }}>
                                Enable 2-Factor Authentication
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginRight: '20px', fontWeight: '500', textAlign: 'center' }}>Mobile OTP </div>
                        <div style={{ marginTop: '20px', fontSize: '14px', marginRight: '20px', textAlign: 'center' }}>Mobile OTP is enabled for Your Account</div>
                    </div>
                </div>
            </Row>

        </>
    );
};

export default FactorAuthentication;

