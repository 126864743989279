import React from "react";
import { Col, Row, Input,Button} from "reactstrap";

const BussinessProfile = () => {

    return (
        <>
            <Row style={{ padding: '15px', fontWeight: '500' }} >
                {/* ...Business Details ...*/}
                <div  >Business Details</div>
                <Col lg={12} className="border-top" style={{ marginTop: '10px' }}>
                    <Col style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}> Business Name (As Mentioned in GST)
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Business Type
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>

                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}>GST Number
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Business PAN
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>
                </Col>
                {/* ....Address Details.....*/}
                <div style={{ marginTop: '20px' }}>Address Details</div>
                <Col lg={12} className="border-top" style={{ marginTop: '10px' }}>
                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}> Address
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Pin code
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>

                    </Col>
                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}>City
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>State
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>
                </Col>
                {/* ....Bank Details ....*/}
                <div style={{ marginTop: '20px' }} >Bank Details</div>
                <Col lg={12} className="border-top" style={{ marginTop: '10px' }}>
                    <Col style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}> Account Name
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>IFSC
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>

                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}>Bank Name
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Branch Name
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>
                </Col>
                <div style={{ marginTop: '20px' }}>Promoter Details (1)</div>
                <Col lg={12} className="border-top" style={{ marginTop: '10px' }}>
                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}> First Name
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Last Name
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>

                    </Col>

                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col style={{ fontWeight: '400' }}>Pan
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                        <Col style={{ fontWeight: '400' }}>Aadhar
                            <Input style={{ border: 'none ', outline: 'none', color: '#585858', marginTop: '5px', padding: '0' }} />
                        </Col>
                    </Col>
                </Col>
                <div className=" mt-3">
                    <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }}>
                        Verify
                    </Button>
                </div>
            </Row>

        </>
    );
};

export default BussinessProfile;

