const PayeesListData = () => {


    return [
        {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          }
    ]
}
       
export { PayeesListData }