import React, { useState } from "react";
import { Col, Row, FormGroup, Label, Input, Button, Form } from "reactstrap";
import axios from "axios";

const SinglePayees = () => {
  const [Chequecancled, setChequecancled] = useState(
    "Upload File( Max size 5MB)"
  );

  let [formData, setFormData] = useState({
    accountHolderName: "",
    email: "",
    mobileNumber: "",
    bankAccNumber: "",
    ifsc: "",
    bankName: "",
    bankbranch: "",
    bankAddress: "",
  });

  let [errorMessage, setErrorMessage] = useState("");
  let [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.accountHolderName) {
      setErrorMessage("Account Holder Name is required.");
    } else if (!formData.bankAccNumber) {
      setErrorMessage("Bank account number is required.");
    } else if (!formData.bankName) {
      setErrorMessage("Bank name is required.");
    } else if (!formData.bankAddress) {
      setErrorMessage("Bank address is required.");
    } else if (!formData.ifsc) {
      setErrorMessage("IFCS code is required.");
    } else if (!formData.mobileNumber) {
      setErrorMessage("Mobile number is required.");
    } else if (!formData.email) {
      setErrorMessage("Email is required.");
    } else {
      setErrorMessage("");
    }

    setLoading(true);

    if (!errorMessage) {
      console.log("formdata", formData);

      // api call

      let url = process.env.REACT_APP_BASEURL + "/api/v1/payee";
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          // "Accept" : "plain/text",
          Authorization: "Bearer " + localStorage.getItem("token"),
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      };

      let body = {
        accountHolder: formData.accountHolderName,
        accountNumber: formData.bankAccNumber,
        bankName: formData.bankName,
        bankAddress: formData.bankAddress,
        ifsc: formData.ifsc,
        mobile: formData.mobileNumber,
        email: formData.email,
      };

      axios
        .post(url, body, config)
        .then((res) => {
          setLoading(false);
          console.log("Result" + res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {errorMessage && (
          <Col className="mt-1 ">
            <div
              className="alert alert-danger d-flex justify-content-between"
              role="alert"
            >
              <div> {errorMessage}</div>
              <div>
                <Button
                  onClick={() => setErrorMessage("")}
                  type="submit"
                  className="close left "
                  style={{ background: "transparent", border: "none" }}
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    &times;
                  </span>
                </Button>
              </div>
            </div>
          </Col>
        )}
        <Row style={{ marginTop: "20px", padding: "15px" }}>
          <Row className="">
            <Col lg="6">
              <FormGroup>
                <Label>Account Holder's Name</Label>
                <Input
                  name="holder"
                  type="text"
                  placeholder="Payee Name"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      accountHolderName: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Col lg="6">
            <FormGroup>
              <Label>Mobile Number</Label>
              <Input
                name="mobile"
                type="number"
                placeholder="Enter Mobile No"
                onChange={(e) => {
                  setFormData({ ...formData, mobileNumber: e.target.value });
                }}
              />
            </FormGroup>
          </Col>

          <h5 style={{ padding: "15px", fontWeight: "bold" }}>Bank Details</h5>
          <Row style={{ padding: "15px", marginTop: "-20px" }}>
            <Col lg="6">
              <FormGroup>
                <Label>Account Number</Label>
                <Input
                  name="acc"
                  type="text"
                  placeholder="Account Number"
                  onChange={(e) => {
                    setFormData({ ...formData, bankAccNumber: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>IFSC Code</Label>
                <Input
                  name="email"
                  type="text"
                  placeholder="Enter IFSC"
                  onChange={(e) => {
                    setFormData({ ...formData, ifsc: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ padding: "15px", marginTop: "-20px" }}>
            <Col lg="6">
              <FormGroup>
                <Label>Bank Name</Label>
                <Input
                  name="bankname"
                  type="text"
                  placeholder="Enter Bank Name"
                  onChange={(e) => {
                    setFormData({ ...formData, bankName: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Bank Branch</Label>
                <Input
                  name="bankbranch"
                  type="text"
                  placeholder="Enter Bank Branch"
                  onChange={(e) => {
                    setFormData({ ...formData, bankbranch: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Bank Address</Label>
                <Input
                  name="bankAddress"
                  type="text"
                  placeholder="Enter Bank Branch"
                  onChange={(e) => {
                    setFormData({ ...formData, bankAddress: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <h5
            style={{ padding: "15px", fontWeight: "bold", marginTop: "-10px" }}
          >
            Documents(s)
          </h5>
          <div className="mb-3">
            <Label className="">Cheque Cancelled</Label>
            <div className="input-group ">
              <Col
                lg={10}
                md={9}
                sm={7}
                style={{
                  border: "1px solid #8080809e",
                  borderRight: "none",
                  borderRadius: "5px",
                }}
              >
                <span
                  className="m-2"
                  style={{ fontSize: "13px", color: "#585858" }}
                >
                  {Chequecancled}
                </span>
              </Col>
              <Col lg={2} md={3} sm={5} style={{ textAlign: "center" }}>
                <input
                  type="file"
                  className=" d-none form-control"
                  id="inputGroupFile08"
                  onChange={(e) => {
                    setChequecancled(e.target.files[0].name);
                  }}
                />
                <label
                  className="input-group-text "
                  style={{
                    background: "#fff",
                    border: "1px solid #8080809e",
                    cursor: "pointer",
                  }}
                  for="inputGroupFile08"
                >
                  Browser
                </label>
              </Col>
              <div className="button-group mt-3">
                {/* <Button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#0149AD", borderColor: "#a2751a" }}
                >
                  Add Payee
                </Button> */}

                {loading ? (
                  <Button
                    type="submit"
                    className="btn header-color"
                    style={{  borderColor: "#0149AD" }}
                    disabled
                  >
                    Add Payee...
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    /> 
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn header-color"
                    style={{  borderColor: "#0149AD" }}
                  >
                    Add Payee
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default SinglePayees;
