import React, { useState } from "react";
import { Col, Row, Button,  FormGroup,Form, Label , Input } from "reactstrap";

import Card from 'react-bootstrap/Card';
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";


const Description = ({onQuery}) => {
     let navigate = useNavigate()

    let [formData, setFormData] = useState({
        accountName: '', accountNameErr: '',
        purpose: '', purposeErr: '',
        email: '', emailErr: '',
        validity: '', validityErr: '',
        startDate: '', startDateErr: '',
        expectedVolume : '' , expectedVolumeErr : ''
    });
    
    const [loading, setLoading] = useState(false)


    const handleSubmit = (e) => {
        console.log("On Submit");
        e.preventDefault();
        onQuery("Kushwah")
        setLoading(true)
        let error = { status: 0, accountName: '', purpose: '' , email : '',validity : '', startDate: '', expectedVolume :''};

        if (formData.accountName <= 0 || formData.accountName === '') { console.log("1"); error.accountName = 'Please enter account name.'; error.status = 1 } else { error.accountName = '' }
        if (formData.purpose <= 0 || formData.purpose ==='') { console.log("2"); error.purpose = 'Please enter purpose.'; error.status = 1 } else { error.purpose = '' }
        if (formData.email <= 0 || formData.email ==='') { console.log("3"); error.email = 'Please enter email.'; error.status = 1 } else { error.email = '' }
        if (formData.validity <= 0 || formData.validity === '') { console.log("4"); error.validity = 'Please enter validity.'; error.status = 1 } else { error.validity = '' }
        if (formData.startDate <= 0 || formData.startDate === '') { console.log("5"); error.startDate = 'Please enter startDate.'; error.status = 1 } else { error.startDate = '' }
        if (formData.expectedVolume <= 0 || formData.expectedVolume === '') { console.log("6"); error.expectedVolume = 'Please enter expected Volume.'; error.status = 1 } else { error.expectedVolume = '' }

        setFormData({ ...formData,
                accountNameErr: error.accountName,
                purposeErr: error.purpose,
                emailErr : error.email,
                validityErr : error.validity,
                startDateErr : error.startDate,
                expectedVolumeErr : error.expectedVolume
            });
            console.log("error.status ",error.status );
        if (error.status === 0) {
            console.log("formData",formData);
            const getlocalStorage = JSON.parse( localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY))

            const config = {
                headers: {
                    // "Access-Control-Allow-Origin": "*",
                    // "Content-Type": "plain/text",
                    // "Accept" : "plain/text",
                    'Authorization' : 'Bearer ' + getlocalStorage.token,
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };


            // let body =  ({
            //     name: "John Doe",
            //     email: "john.doe@gmail.com",
            //     purpose: "Recieve and transfer funds for cars",
            //     startDate: "2013-03-06T00:00:00Z",
            //     validity: 90,
            //     expectedVolume: 500000,
            //     bank: "icici",
            //     documentIds: [
            //     "DC9039302951"
            //     ]
            //     })

            let body =  ({
                name: formData.accountName,
                email: formData.email,
                purpose: formData.purpose,
                startDate: formData.startDate,
                validity: formData.validity,
                expectedVolume: formData.expectedVolume,
                bank: "icici",
                documentIds: [
                "DC9039302951"
                ]
                })
 
            let url = process.env.REACT_APP_BASEURL + "/api/v1/account"         
        
            axios.post(url, body, config)
                .then(res => {
                    setLoading(false);
                    console.log("res", JSON.stringify(res));
                   
                }).catch(error => {
                    console.log("ERRRR",error);
                    setLoading(false);
                    if (error) {
                        const getErrRes = errorHandler(error)
                        // const getErrRes = ''
                        if (getErrRes === 401) {
                            navigate("/");
                        } else {
                            toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                                theme: "colored",
                            });
                        }
                    }
                })
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            
            <Row style={{ marginTop: '20px' }} >
                <Col lg={12}>
                    <Card >
                       
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label htmlFor="exampleAccount">Account Name</Label>
                                    

                                    <Input  type="text" 
                                            placeholder='Enter the name of account'
                                            name="account"
                                            defaultValue={formData.accountName}
                                            onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, accountName: e.target.value, accountNameErr: '' }) : setFormData({ ...formData, accountName: '', accountNameErr: 'Account name is required.' }) }}
                                        ></Input>


                                    <small className="text-danger">{formData.accountNameErr}</small>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="payer">Purpose</Label>
                                    <Input id="payer" name="payername" placeholder="Enter the purpose of account" type="text"
                                        defaultValue={formData.purpose}
                                        onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, purpose: e.target.value, purposeErr: '' }) : setFormData({ ...formData, purpose: '', purposeErr: 'Purpose is required.' }) }}
            
                                    />
                                    <small className="text-danger">{formData.purposeErr}</small>

                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="pdetails1">Email</Label>
                                    <Input id="pdetails1" name="details1" placeholder="Enter email address that will receive email notifications regarding Deposits & Transfer in the account"
                                     type="email"
                                     onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, email: e.target.value, emailErr: '' }) : setFormData({ ...formData, email: '', emailErr: 'Email is required.' }) }}
                                     />
                                    <small className="text-danger">{formData.emailErr}</small>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="pdetails2">Validity</Label>
                                    <Input id="pdetails2" name="details2" placeholder="Enter the validity of account (in days)" type="text" 
                                    onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, validity: e.target.value, validityErr: '' }) : setFormData({ ...formData, validity: '', validityErr: 'validity is required.' }) }}
                                     />
                                    <small className="text-danger">{formData.accountNameErr}</small>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="amountfig">Start Date</Label>
                                    <Input id="amountfig" name="amount" placeholder="DD MM YYYY" type="date" 
                                     onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, startDate: e.target.value, startDateErr: '' }) : setFormData({ ...formData, startDate: '', startDateErr: 'Start Date is required.' }) }}
                                     />
                                    <small className="text-danger">{formData.startDateErr}</small>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="amountword">Expected Volume</Label>
                                    <Input id="amountword" name="amtword" placeholder="Enter the amount in INR"  type="text"
                                      onChange={(e) => { e.target.value !== '' ? setFormData({ ...formData, expectedVolume: e.target.value, expectedVolumeErr: '' }) : setFormData({ ...formData, expectedVolume: '', expectedVolumeErr: 'Start Date is required.' }) }}
                                     />
                                    <small className="text-danger">{formData.expectedVolumeErr}</small>

                                </FormGroup>
                                <div >
                                    <Button  type="submit" className="btn header-color" style={{  borderColor: '#0149AD' }}>
                                        Next
                                    </Button>
                                </div>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Description;