import React ,{useState,useEffect} from 'react'
import { Col, Row, Button, Table, Input } from "reactstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const AddRetailer = () => {
  const [sendImage, setSendImage] = useState(false);
  let [loading, setLoading] = useState(false);

  let [addData, setAddData] = useState({
    // public_id: 0,
    name: '', nameerr: '',
    phone_number: '', phone_numbererr: '',
    email: '', emailerr: '',
    role: '',
    select_dist:"",select_disterr:'',
  });
  const [distList, setDistList] = useState([]);


  useEffect(() => {
    DistList()
  }, []);

  const DistList = () => {

    let url = process.env.REACT_APP_BASEURL + "v1/asset/dist/"
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')
      }
    };

    axios.get(url, config)
      .then(res => {
        console.log("fghgfj", res.data)
        setDistList(res.data.data);
      }
      ).catch(err => {

      })
  }

  const retailer = (event) => {
    event.preventDefault();
    setLoading(true)
    let error = { status: 0, name: '', lastname: '', email: '' };
    if (addData.name == '') { error.name = 'Please enter name.'; error.status = 1 } else { error.name = '' }
    if (addData.phone_number <= 0 || addData.phone_number == '') { error.phone_number = 'Please enter phone_number.'; error.status = 1 } else { error.phone_number = '' }
    if (addData.email <= 0 || addData.email == '') { error.email = 'Please enter email.'; error.status = 1 } else { error.email = '' }
    setAddData({ ...addData, nameerr: error.name, phone_numbererr: error.phone_number, emailerr: error.email, });
    if (error.status == 1) {
      setLoading(false);
    }
    if (error.status == 0) {
      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization": localStorage.getItem('escrowToken')

        },
      };

      let url = process.env.REACT_APP_BASEURL + 'v1/asset/retailer/'
      const formData = new FormData();

      if (!(sendImage === false)) {
        formData.append("profile", sendImage);
      }
      formData.append("name", addData.name);
      formData.append("email", addData.email);
      formData.append("phone_number", addData.phone_number);
      formData.append("role", 'retailer');
 formData.append("dist_id", addData.select_dist);

      axios.post(url, formData, config).then((res) => {
        setLoading(false);
        setAddData({ ...addData, })

        toast.success("Added successfully", {
          autoClose: 3000,
          theme: "colored",
        });

      }).catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          autoClose: 3000,
          theme: "colored",
        });
      });
    }
  };
  return (
   <>
     <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h2 className="text-white">
            {" "}
          Add Retailer
          </h2>
          <span className="text-white"> </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                <div style={{ marginLeft: '20px' }}>Add Retailer Form</div>
                <div style={{ marginRight: '20px',color: '#0149AD', fontWeight: '400'  }}>
                  <nav aria-label="breadcrumb" >
                    <ol className="breadcrumb ml-5 ">
                      <li className="breadcrumb-item"><Link to="/retailer"  style={{ textDecoration: "none" ,color:'#1b385b'}}>Retailer List</Link></li>
                      <li className="breadcrumb-item active" aria-current="page" ><Link style={{ textDecoration: "none", }}>Add Retailer Form</Link></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={retailer}>
                <Row className="mt-3">
                  <Col>
                    <label> Name</label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Name"
                      className="mt-2"
                      defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'Full name is required.' }) }}
                    />
                    <small className="text-danger">{addData.nameerr}</small>
                  </Col>
                  <Col>
                    <label>Email</label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="mt-2"
                      defaultValue={addData.email}
                      onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'Email  is required.' }) }}
                    />
                    <small className="text-danger">{addData.emailerr}</small>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <label>Mobile no</label>
                    <Input
                      name="mobile"
                      type="tel"
                      placeholder="Mobile No"
                      className="mt-2"
                      defaultValue={addData.phone_number} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, phone_number: e.target.value, phone_numbererr: '' }) : setAddData({ ...addData, phone_number: '', phone_numbererr: 'Phone number  is required.' }) }}
                    />
                    <small className="text-danger">{addData.phone_numbererr}</small>
                  </Col>
                  <Col>
                    <label>Dist</label>
                    <Form.Select className="mt-2" onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, select_dist: e.target.value, select_disterr: '' }) : setAddData({ ...addData, select_dist: '', select_disterr: 'select Retailer   is required.' }) }} >
                    <option disabled selected >-Select Sub Admin</option>
                      {distList.map((retailer, index) => {
                        return(<>
                          <option value={retailer.eid} >{retailer.name}</option>
                        </>)
                        
                      })}
                    
                  
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mt-3">


<Col className="mt-2 d-flex flex-column" lg='3'>
  <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Select All</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Account List</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Single Transfer</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Bulk Transfer</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Manage Payees List</span></div>
  <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Manage Teams</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >My Profile</span></div>
</Col>
<Col className="mt-2 d-flex flex-column" lg='3'>
  <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Inward Funds Deposit</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Outward Funds Deposit</span></div>
  <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Challen List</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Create Challan Page</span></div>
  <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Escrow List</span></div>
  <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Create Escrow Page</span></div>
</Col>
</Row>
                <Row className="mt-4">
                  <Col>
                    <Button className="header-color" style={{ border: "none" }}>
                      Submit
                    </Button>

                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
   </>
  )
}

export default AddRetailer
