import React, { useEffect, useState } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { BsFunnel } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import axios from "axios";

const EscrowList = () => {
  const [escrowList, setEscrowList] = useState([
    {
      escrowPageId: "EP0000000000",
      url: "https://bit.ly/37memr7",
      createdAt: "2020-12-07T06:35:14.407Z",
      title: "Loan collection",
      action: "true",
    },
    {
      escrowPageId: "EP0000000000",
      url: "https://bit.ly/37memr7",
      createdAt: "2020-12-07T06:35:14.407Z",
      title: "Loan collection",
      action: "false",
    },
    {
      escrowPageId: "EP0000000000",
      url: "https://bit.ly/37memr7",
      createdAt: "2020-12-07T06:35:14.407Z",
      title: "Loan collection",
      action: "false",
    },
  ]);


  useEffect(() => {
    getEscrowList();
  }, [])


const getEscrowList = () => {
  let url = process.env.REACT_APP_BASEURL + "/api/v1/escrow-page";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        // console.log(res.data);
        setEscrowList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
}

  return (
    <>
      <Row>
        <Col className="header-color" 
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            <CiMobile4></CiMobile4>Escrow Pages
          </h3>
          <h5 className="text-white ">Access your escrow pages here.</h5>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header className="subheader-color" style={{ backgroundColor: "", padding: "15px" }}>
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD" }}
              >
                <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  Escrow pages
                </div>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#0149AD",
                      borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginRight: "3px",
                    }}
                  >
                    <FiRefreshCw />{" "}
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#0149AD",
                      borderRadius: "50%",
                      borderColor: "#0149AD",
                    }}
                  >
                    <BsFunnel />{" "}
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-2">
                <Col sm="6">
                  <Input
                    name="search"
                    type="text"
                    placeholder="Search by Template Name,Virtual ID"
                  />
                </Col>
                <Col sm="2">
                  <Input name="search" type="date" placeholder="Start Date" />
                </Col>
                <Col sm="2">
                  <Input name="search" type="date" placeholder="End Date" />
                </Col>
                <Col sm="2">
                  <Button
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "5px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span> clear</span>
                </Col> 
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Escrow Page ID</th>
                    <th>Template Name</th>
                    <th>Creation Date</th>
                    <th>URL</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    escrowList?.length > 0 &&
                    escrowList.map((item, index) => {
                        console.log(item)
                      return (
                        <tr key={index} className="border-top">
                          <td>{item.escrowPageId}</td>
                          <td>{item.title}</td>
                          <td>{item.createdAt}</td>
                          <td>{item.url}</td>
                          <td>{item.action}</td>
                        </tr>
                      );
                    })}
                    {escrowList?.length === 0 && <h4>No Data Available</h4>}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EscrowList;
