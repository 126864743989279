import React, { useState } from "react";
import { Col, Row, Button, Table, Form, Label, Input } from "reactstrap";
import { FiBook } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
const ManageTeams = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
  });
  let [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.firstName) {
      setErrorMessage("First Name is required.");
    } else if (!formData.lastName) {
      setErrorMessage("Last Name is required.");
    } else if (!formData.email) {
      setErrorMessage("Email is required.");
    } else if (!formData.phone) {
      setErrorMessage("Phone number is required.");
    } else if (!formData.role) {
      setErrorMessage("Role is required.");
    } else {
      setErrorMessage("");
    }

    setLoading(true);

    if (!errorMessage) {
      setLoading(false)
      console.log("FOormData ", formData);
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      <Row>
        <Col className="header-color" 
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <FiBook /> Manage Teams
          </h3>
          <span className="text-white">Manage your team here.</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header className="subheader-color" style={{ backgroundColor: "", padding: "20px" }}>
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD" }}
              >
                <div style={{ fontWeight: "bold" }}> Manage Team</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3">
                <Col>
                  <span style={{ fontWeight: "bold" }}>Team Members</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button className="header-color" 
                    style={{
                      border: "none",
                      marginRight: "5px",
                    }}
                    onClick={handleShow}
                  >
                    Invite New Member{" "}
                  </Button>
                </Col>
              </Row>
              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Member Name</th>
                    <th>Mobile number</th>
                    <th>Email ID</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-top">
                    <td>1</td>
                    <td>Laetitia Shakti</td>
                    <td>9778503865</td>
                    <td>founder@laetitiafinser.com</td>
                    <td style={{ color: "green", fontWeight: "bold" }}>
                      Owner
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="d-flex justify-content-center">
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              Invite New Member
            </span>
          </Modal.Header>
          <Modal.Body>
            {errorMessage && (
              <Col className="mt-1 ">
                <div
                  className="alert alert-danger d-flex justify-content-between"
                  role="alert"
                >
                  <div> {errorMessage}</div>
                  <div>
                    <button
                      onClick={() => setErrorMessage("")}
                      type="button"
                      className="close left "
                      style={{ background: "transparent", border: "none" }}
                      aria-label="Close"
                    >
                      <span
                        aria-hidden="true"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        &times;
                      </span>
                    </button>
                  </div>
                </div>
              </Col>
            )}
            <Label>First Name</Label>
            <Input
              type="text"
              placeholder="Enter First Name"
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            ></Input>
            <Label className="mt-2">Last Name</Label>
            <Input
              type="text"
              placeholder="Enter Last Name"
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            ></Input>
            <Label className="mt-2">Email</Label>
            <Input
              type="email"
              placeholder="Enter email address"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            ></Input>
            <Label className="mt-2">Phone</Label>
            <Input
              type="number"
              placeholder="Enter Phone Number"
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
              }}
            ></Input>

            <Label
              className="mt-2"
            >
              Role
            </Label>
            <Input type="select" onChange={(e) => {
                setFormData({ ...formData, role: e.target.value });
              }}>
              <option value="">Select Role</option>
              <option>Admin</option>
              <option>Sub-admin</option>
              <option>Owner</option>
            </Input>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ background: "#dedede", border: "none", color: "black" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {/* <Button
              style={{ background: "#a2751a", border: "none" }}
              // onClick={handleClose}
            >
              Invite
            </Button> */}

            {loading ? (
              <Button
                type="submit"
                className="btn"
                style={{ background: "#a2751a", border: "none" }}
                disabled
              >
                Invite...
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button
                type="submit"
                className="btn"
                style={{ background: "#a2751a", border: "none" }}
              >
                Invite
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ManageTeams;
