import React from "react";
import { Col, Row, } from "reactstrap";
import { FaEdit } from "react-icons/fa"



const Notification = () => {

    return (
        <>
            <Row style={{ padding: '15px', fontWeight: '500' }} >
                <div  >Notification Setting</div>
                <Col lg={12} className="border-top" style={{ marginTop: '10px' }}>
                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col sm="6" md="6" style={{ fontWeight: '500' }}> Email Address</Col>
                        <Col sm="6" md="6" style={{ fontWeight: '100' }}>founder@taetitserv.com</Col>
                    </Col>
                    <Col lg={12} style={{ marginTop: '20px', display: 'flex' }}>
                        <Col sm="6" style={{ fontWeight: '500' }}>Mobile Number</Col>
                        <Col sm="6" style={{ fontWeight: '100' }}>9778503865 &nbsp;<FaEdit style={{color:'#009fff'}}/></Col>
                    </Col>
                </Col>
            </Row>

        </>
    );
};

export default Notification;

