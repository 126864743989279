import React from 'react'
import { Col, Row, Input, Table, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";

const ScheduledTransferTab = () => {
    return (
        <div>
            <Row style={{ marginTop: '5px', padding: '15px', }} >
                <Row className="mt-3 " >
                    <Col   >
                        <Input name="search" type="text" placeholder="Search" />
                    </Col>
                    <Col >
                        <Button style={{ backgroundColor: '#a2751a', border: 'none', marginLeft: '-20px', marginRight: '5px' }}><FaSearch /> </Button>
                        <span style={{ color: '#0149AD', marginRight: '5px' }}> clear</span>


                    </Col>
                </Row>

                <Table className="no-wrap mt-3 align-middle border-top" responsive borderless>
                    <thead className='fs-6'>
                        <tr>
                            <th>Scheduled Id</th>

                            <th>Account Number</th>
                            <th>Payee</th>
                            <th>Amount</th>
                            <th>Start Date</th>
                            <th>Frequency </th>
                            <th>Remaining Payments </th>
                            <th>Next Payment on</th>
                            <th>Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-top">
                            <td>123456</td>
                            <td>89898***</td>
                            <td>07/08/2020</td>
                            <td>8878</td>
                            <td>07/08/2020</td>
                            <td>5456</td>
                            <td>0</td>
                            <td>0</td>
                            <td>N/A</td>
                        </tr>
                    </tbody>

                </Table>
            </Row>
        </div>
    )
}

export default ScheduledTransferTab;