const FundDepositListData = () => {


    return [
        {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Praveen Dhakad",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Rituraj Dhabake",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Tanmay Akolkar",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Praveen Dhakad",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Rituraj Dhabake",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Tanmay Akolkar",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Praveen Dhakad",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Rituraj Dhabake",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Tanmay Akolkar",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Praveen Dhakad",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Rituraj Dhabake",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
          {
            paymentMode: "NEFT",
            amount: 304900,
            accountId: "PPAY89389893",
            remitterName: "Tanmay Akolkar",
            remitterAccNumber: "98729837982735",
            remitterIFSCCode: "ICICI00000000",
            remarks: "Remarks",
            date: "2020-12-14T05:37:51.465Z",
            accountBalance: 64,
            status: "Accept",
            utr: "9513578520",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-07T06:35:14.407Z",
            merchantTitle: "ABC Private Limited",
            merchantId: "MR2567189948",
            accountName: "ICICI Name",
            createdBy: "vishal@castler.com",
            transactionId: "TX889779799",
          },
    ]
}

export { FundDepositListData }