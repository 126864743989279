import React, { useState } from "react";
import { Row, Table } from "reactstrap";
import Excel from "../../../assets/images/logos/excle_log.png";
import { FaFileUpload } from "react-icons/fa";
import Papa from "papaparse";

const MultiplePayees = () => {
  // let [fileName, setFileName] = useState('')
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [errorValues, setErrorValues] = useState([]);

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setParsedData(results.data);
        setTableRows(rowsArray[0]);
        setValues(valuesArray);

      },
    });
  };
  return (
    <>
      <Row style={{ padding: "15px" }}>
        <div className="d-flex justify-content-between mt-5">
          <div style={{ fontSize: "15px" }}>
            Enter The requird details in the template and upload (.XLS, .XLSX,
            .CSV)
          </div>
          <div className="pe-5">
            Download Template{" "}
            <img
              src={Excel}
              style={{ height: "28px", width: "28px" }}
              alt="excel"
            ></img>
          </div>
        </div>
        <Row className="mt-3 p-5">
          <label
            for="file-upload"
            className="custom-file-upload text-center "
            style={{
              border: "1px solid #ccc",
              display: "inline-block",
              padding: " 26px",
              boxShadow: "0px 1px 16px 1px #00bcd459",
              cursor: " pointer",
            }}
          >
            Upload File <FaFileUpload />
            <input
              id="file-upload"
              type="file"
              name="file"
              accept=".csv"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <br />
            <span id="imageName" style={{ color: " green" }}>
              {/* {fileName} */}
            </span>
          </label>
        </Row>
        <div className="d-flex justify-content-between">
          <div style={{ fontSize: "15px" }}>
            Invalid entries in your uploaded file will be displayed here
          </div>
        </div>

        <Table
          className="no-wrap mt-3 align-middle border-top"
          responsive
          borderless
        >
          <thead>
            <tr>
              <th>Excel row no</th>
              <th>Payee name</th>
              <th>Email ID</th>
              <th>Mobile</th>
              <th>Bank Account No.</th>
              <th>Bank IFSC</th>
              <th>Bank Name</th>
              <th>Bank Branch</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => {
              return (
                <tr key={index} className="border-top">
                  {value.map((val, i) => {
                    console.log(val)
                    return <td key={i}>{val}</td>;
                  })}
                </tr>
              );
            })}
            {/* <tr className="border-top">
              <td>1</td>
              <td>Avnish</td>
              <td>avi@gmail.com</td>
              <td>9876567899</td>
              <td>7896*****</td>
              <td>IFSC14576</td>
              <td>BOI</td>
              <td>Indore</td>
              <td>ftrjh</td>
            </tr> */}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default MultiplePayees;
