import React, { useState } from "react";
import { Col, Row, Input, FormGroup, Form, Label, Button } from "reactstrap";
import { BsDownload } from "react-icons/bs";
import Card from 'react-bootstrap/Card';
import axios from "axios";

const ChallanCreate = () => {

    let [formData, setFormData] = useState({
        account: '',
        payerName: '',
        payerDetails1 : '',
        payerDetails2 : '',
        contact1: '', 
        contact2: '',
        amountInFig: '',
        amountInWords: ''
    });
    let [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        if(!formData.account){
            setErrorMessage('Account Name is required.')
        } else if(!formData.payerName){
            setErrorMessage("Payer Name is required.")
        } else if(!formData.payerDetails1){
            setErrorMessage("Payer Details 1 is required.")
        } else if(!formData.payerDetails2){
            setErrorMessage("Payere Details 2 is required.")
        } else if(!formData.contact1){
            setErrorMessage("Contact 1 is required.")
        } else if(!formData.amountInFig){
            setErrorMessage("Amount in figure is required.")
        } else if(!formData.amountInWords){
            setErrorMessage("Amount in words is required.")
        } else {
            setErrorMessage('')
        }


        setLoading(true)

        if(!errorMessage){
            console.log("FormData", formData);

            // api call

            let url = process.env.REACT_APP_BASEURL + "/api/v1/challan";
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    // "Accept" : "plain/text",
                    'Authorization' : 'Bearer ' + localStorage.getItem("token"),
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };

            let body = {
                accountId: formData.account,
                payerName: formData.payerName,
                payerDetail1: formData.payerDetails1,
                payerDetail2: formData.payerDetails2,
                contact1: formData.contact1,
                contact2: formData.contact2,
                amount: formData.amountInFig,
                amountWords: formData.amountInWords
            }
            
            axios.post(url, body, config).then((res) => {
                setLoading(false);
                console.log("Result" + res.data)
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })

        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <Row>
                <Col className="header-color" style={{  padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{fontWeight:"bold"}}><BsDownload /> Challan</h3>
                    <h5 className="text-white ">Generate your Challan here</h5>

                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '15px' }}>
                            <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                                <div style={{ marginLeft: '20px',fontWeight:"bold" }}>Challans Form</div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                            {errorMessage && 
                                    <Col className="mt-1 " >
                                        <div className="alert alert-danger d-flex justify-content-between" role="alert">
                                            <div>  {errorMessage}</div>
                                            <div>
                                                <Button onClick={() => setErrorMessage('')} type="submit" className="close left " style={{ background: 'transparent', border: 'none' }} aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>&times;</span>
                                                </Button>
                                            </div>

                                        </div>
                                    </Col>
                            }
                                <FormGroup>
                                    <Label htmlFor="exampleAccount">Account</Label>
                                    <Input id="exampleAccount" name="account" onChange={(e) => {setFormData({ ...formData, account: e.target.value})}} placeholder="Enter Account Name" type="text" />
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="payer">Payer Name</Label>
                                    <Input id="payer" name="payername" type="text" onChange={(e) => {setFormData({ ...formData, payerName: e.target.value})}} /> 
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="pdetails1">Payer Details 1</Label>
                                    <Input id="pdetails1" name="details1" type="text" onChange={(e) => {setFormData({...formData, payerDetails1: e.target.value})}} />
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="pdetails2">Payer Details 2</Label>
                                    <Input id="pdetails2" name="details2" type="text" onChange={(e) => {setFormData({...formData, payerDetails2: e.target.value})}} />
                                </FormGroup>

                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label htmlFor="contact1">Contact 1</Label>
                                            <Input id="contact1" name="contact1" type="number" onChange={(e) => {setFormData({...formData, contact1: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label htmlFor="contact2">Contact 2</Label>
                                            <Input id="contact12" name="contact2" type="number" onChange={(e) => {setFormData({...formData, contact2: e.target.value})}} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label htmlFor="amountfig">Amount (in fig)</Label>
                                    <Input id="amountfig" name="amount" type="number" onChange={(e) => {setFormData({...formData, amountInFig: e.target.value})}} />
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="amountword">Amount (in word)</Label>
                                    <Input id="amountword" name="amtword" type="text" onChange={(e) => {setFormData({...formData, amountInWords: e.target.value})}} />
                                </FormGroup>
                                <div className="button-group">
                                    {/* <Button className="btn" style={{ backgroundColor: '#a2751a', borderColor: '#a2751a' }}>
                                        Submit
                                    </Button> */}
                                    {loading ?
                                            <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }} disabled>Submit...
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            </Button> :

                                            <Button type="submit" className="btn header-color" style={{  borderColor: '#0149AD'}}>Submit</Button>
                                    }
                                </div>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ChallanCreate;
