
import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "./Admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { FaTrashAlt, FaEdit, FaUserTie } from "react-icons/fa"
import { RxCross2 } from "react-icons/rx"
const Admin = () => {
  let navigate=useNavigate()
  const [adminList, setAdminList] = useState([]);
  
  useEffect(() => {
    AdminList()
  }, []);

  const AdminList = () => {

    let url = process.env.REACT_APP_BASEURL + "v1/asset/sub_admin/"
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')
      }
    };

    axios.get(url, config)
      .then(res => {
        console.log("fghgfj", res.data)
        setAdminList(res.data.data);
      }
      ).catch(err => {

      })
  }

  const AdminDelete = (eid) => {
    let url = process.env.REACT_APP_BASEURL + `v1/asset/${eid}/`
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization":   localStorage.getItem('escrowToken')
        }
    };
   
    axios.delete(url, config)
        .then((res) => {
           
            toast.success("Deleted successfully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                theme: "colored",
            })
        }
        )
        .catch((error) => {
            console.log(error);
           
        })

}


  return (
    <>
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <FaUserTie />Sub Admin
          </h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{ fontWeight: 'bold' }}>Sub Admin</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3 ">
                <Col xs="6">
                  <Input
                    name="search"
                    type="text"
                    placeholder="Search by Name"
                  />
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                  <Link to="/addadmin"><Button className="header-color" style={{ border: 'none' }}>+ Add </Button></Link>
                </Col>
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    {/* <th>Profile</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminList.map((admin, index) => {
                    return (<tr>
                      <td>{admin.name}</td>
                      <td>{admin.username}</td>
                      <td>{admin.phone_number}</td>
                      {/* <td><img src={admin.avatar} style={{height:"30px"}}></img></td> */}
                      <td>
                        <FaEdit className="ms-2 " style={{color:'rgb(39 104 182'}} onClick={()=>navigate(`/updateadmin/${admin.eid}`)}></FaEdit>
                        {/* <FaTrashAlt className="ms-3 text-danger" onClick={() => AdminDelete(admin.eid)}></FaTrashAlt> */}
                      </td>
                    </tr>)
                  })}
                </tbody>

              </Table>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Admin;

