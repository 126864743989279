import React from "react";
import { Col, Row, Input, FormGroup, Label, Table, Button } from "reactstrap";
import { BsDownload } from "react-icons/bs";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const CreateEscrow = () => {

    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}><BsDownload /> Create Escrow</h3>
                    <h5 className="text-white ">Build your escrow here</h5>

                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '15px' }}>
                            <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                                <div style={{ marginLeft: '20px', fontWeight: "bold" }}>Account Details</div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <FormGroup>
                                    <Label htmlFor="exampleAccount">Page Title</Label>
                                    <Input id="exampleAccount" name="account" placeholder="Enter your page title" type="text" />
                                </FormGroup>
                                <FormGroup>
                                <label>Accounts</label>
                                <Form.Select className="mt-2">
                                    <option >Select Accounts</option>
                                    <option disabled>No Items Found</option>
                                </Form.Select>
                                </FormGroup>
                            </Form>

                            <Table className="no-wrap mt-5 align-middle border-top" responsive borderless>
                                <thead>
                                    <tr>
                                        <th>Account Name</th>
                                        <th>Account Number</th>
                                        <th>Account IFSC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-top">
                                        <td>castler</td>
                                        <td>123456****</td>
                                        <td>ifsc12887</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Col className="d-flex justify-content-end">
                                <Button className="btn mt-1 header-color " style={{  borderColor: '#0149AD' }}>
                                    Next
                                </Button>
                            </Col>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CreateEscrow;
