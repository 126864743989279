import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Input, Table, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import axios from "axios";
import { BulktransFerListData } from "./mokeData/bulktransferListData";
import CustomPagination from "../Pagination/CustomPagination";
const BulkTransferTab = () => {
  let PageSize = 10;

  const [bulkTransferList, setBulkTransferList] = useState(
    BulktransFerListData()
  );

  useEffect(() => {
    getBulkTransferList();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);

  let [filterParams, setFilterParams] = useState({
    dateFrom: "",
    dateTo: "",
    s: "",
    accountId: "",
  });

  const onSearch = () => {
    if (filterParams) {
      getBulkTransferList();
    }
  };

  const getBulkTransferList = () => {
    let url =
      process.env.REACT_APP_BASEURL +
      "/api/v1/multi-transfer?page=" +
      currentPage +
      "&s=" +
      filterParams.s +
      "&dateFrom=" +
      filterParams.dateFrom +
      "&dateTo=" +
      filterParams.dateTo;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        // console.log(res.data);
        setBulkTransferList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useMemo(() => {
    getBulkTransferList();
  }, [currentPage]);

  return (
    <div>
      <Row style={{ marginTop: "5px", padding: "15px" }}>
        <Row className="mt-3 ">
          <Col lg={4} className="p-1 ps-2">
            <Input
              name="search"
              type="text"
              value={filterParams.s}
              onChange={(e) => {
                setFilterParams({ ...filterParams, s: e.target.value });
              }}
              placeholder="Search by Virtual ID, Transaction ID, Remitter name"
            />
          </Col>
          <Col className="p-1">
            <Input
              value={filterParams.dateFrom}
              onChange={(e) => {
                setFilterParams({ ...filterParams, dateFrom: e.target.value });
              }}
              name="dateFrom"
              type="date"
            />
          </Col>
          <Col className="p-1">
            <Input
              name="dateTo"
              value={filterParams.dateTo}
              onChange={(e) => {
                setFilterParams({ ...filterParams, dateTo: e.target.value });
              }}
              type="date"
            />
          </Col>
          {/* <Col className="p-1">
            <Input name="status" type="select">
              <option>status</option>
            </Input>
          </Col> */}
          <Col>
            <Button
              onClick={() => onSearch()}
              style={{
                backgroundColor: "#a2751a",
                border: "none",
                marginRight: "5px",
              }}
            >
              <FaSearch />{" "}
            </Button>
            <span
              style={{ color: "#0149AD", marginRight: "5px" }}
              onClick={() => {
                setFilterParams({
                  dateFrom: "",
                  dateTo: "",
                  s: "",
                  accountId: "",
                });
                getBulkTransferList("");
              }}
            >
              {" "}
              clear
            </span>
            <Button
              style={{
                backgroundColor: "#0149AD",
                borderRadius: "50%",
                borderColor: "#0149AD",
                marginLeft: "8px",
              }}
            >
              <AiOutlineArrowDown />{" "}
            </Button>
          </Col>
        </Row>

        <Table
          className="no-wrap mt-3 align-middle border-top"
          responsive
          borderless
        >
          <thead className="fs-6">
            <tr>
              <th>Batch Id</th>
              <th>Batch Name</th>
              <th>Account Number</th>
              <th>Date</th>
              <th>Total Amount</th>
              <th>Status </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bulkTransferList?.length > 0 &&
              bulkTransferList.map((item, index) => {
                return (
                  <tr key={index} className="border-top">
                    <td>{item.batchId}</td>
                    <td>{item.batchName}</td>
                    <td>{item.accountId}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.totalAmount}</td>
                    <td>{item.status}</td>
                    <td>{item.active}</td>
                  </tr>
                );
              })}
            {bulkTransferList?.length === 0 && <h4>No Data Available</h4>}
          </tbody>
        </Table>
        <CustomPagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={bulkTransferList.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Row>
    </div>
  );
};

export default BulkTransferTab;
