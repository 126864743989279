
import React, { useState,useEffect,useMemo} from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "./Admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate,Link } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
// import { AccountListData } from "./AccountListData";
// import CustomPagination from "../Pagination/CustomPagination";
const Dist = () => {
  const [distList, setDistList] = useState([]);
  let navigate=useNavigate()


  useEffect(() => {
    DistList()
  }, []);
 const DistList = () => {

    let url = process.env.REACT_APP_BASEURL + "v1/asset/dist/"
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')
      }
    };

    axios.get(url, config)
      .then(res => {
        console.log("fghgfj", res.data)
        setDistList(res.data.data);
      }
      ).catch(err => {

      })
  }

  return (
    <>
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <CiMobile4 /> Dist
          </h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{  fontWeight: 'bold' }}> Dist</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3 ">
                <Col xs="6">
                  <Input
                 
                    type="text"
                    placeholder="Search by Name"
                  />
                </Col>
                <Col xs="6" className="d-flex justify-content-end ">
                <Link to="/adddist"><Button className="header-color" style={{ border:'none'}}>+ Add </Button></Link>  

                </Col>
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    {/* <th>Profile</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {distList.map((dist, index) => {
                    return (<>{dist.length==0?<tr><td>No Data Found</td></tr> :<tr>
                      <td>{dist.name}</td>
                      <td>{dist.username}</td>
                      <td>{dist.phone_number}</td>
                      {/* <td>{dist.avatar}</td> */}
                      <td>
                      <FaEdit className="ms-2 " style={{color:'rgb(39 104 182'}} onClick={()=>navigate(`/updatedist/${dist.eid}`)}></FaEdit>

                        {/* <FaTrashAlt className="ms-3 text-danger" ></FaTrashAlt> */}
                      </td>
                    </tr>}</>)
                  })}
                </tbody>

              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dist;

