import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FiUser } from "react-icons/fi"
import { Col, Row,} from "reactstrap";
import Card from 'react-bootstrap/Card';
import MultiplePayees from "./MultiplePayees";
import SinglePayees from "./SinglePayees";

const PayeeTabs = () => {
    return (
        <>
            <Row>
                <Col  className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "500" }}><FiUser/>Add New Payee</h3>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Body  style={{  padding: '0px' }}>
                            <Tabs defaultActiveKey="singlepayee" id="uncontrolled-tab-example" className="subheader-color" style={{marginTop:'-15px',}}>
                                <Tab eventKey="singlepayee" title="Single Payee"><SinglePayees/></Tab>
                                <Tab eventKey="multiplepayee" title="Multiple Payees"><MultiplePayees/></Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
          
        </>
    );
}

export default PayeeTabs;