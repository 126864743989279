
import React, { useEffect, useState } from "react";
import { Col, Row, Input, Table, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import axios from "axios";
const SingleTransferTab = () => {
  let [singleTransferList, setSingleTransferList] = useState([
    {
      accountId: "PPAY9903909390",
      accountBalance: 64,
      amount: 34444, 
      purpose: "Buying Property",
      beneficiaryAccNumber: "98753645123",
      beneficiaryIFSC: "PUNB0663600",
      beneficiaryName: "John Doe",
      beneficiaryBankName: "Punjab National Bank",
      status: "pending",
      utr: "9875823214",
      transferId: "TFM9875364125",
      createdAt: "2020-12-07T06:35:14.407Z",
      updatedAt: "2020-12-07T06:35:14.407Z",
      merchantTitle: "ABC Private Limited",
      createdBy: "jane.doe@gmail.com",
      remarks: "Payouts to ABC Business",
      createdAtString: "2020-12-07T06:35:14.407Z\t",
      updatedAtString: "2020-12-07T06:35:14.407Z\t",
      payeeId: "PY4659871230",
      transferType: "NEFT",
      notes: {
        key1: "value1",
        key2: "value2",
      },
      action: "true"
    },
    {
      accountId: "PPAY9903909390",
      accountBalance: 64,
      amount: 34444,
      purpose: "Buying Property",
      beneficiaryAccNumber: "98753645123",
      beneficiaryIFSC: "PUNB0663600",
      beneficiaryName: "Dr Dre",
      beneficiaryBankName: "Punjab National Bank",
      status: "pending",
      utr: "9875823214",
      transferId: "TFM9875364125",
      createdAt: "2020-12-07T06:35:14.407Z",
      updatedAt: "2020-12-07T06:35:14.407Z",
      merchantTitle: "ABC Private Limited",
      createdBy: "jane.doe@gmail.com",
      remarks: "Payouts to ABC Business",
      createdAtString: "2020-12-07T06:35:14.407Z\t",
      updatedAtString: "2020-12-07T06:35:14.407Z\t",
      payeeId: "PY4659871230",
      transferType: "NEFT",
      notes: {
        key1: "value1",
        key2: "value2",
      },
      action: "true"
    },
  ]);

  useEffect(() => {
    getSingleTransferList();
  }, []);

  const getSingleTransferList = () => {
    let url = process.env.REACT_APP_BASEURL + "/api/v1/transfer";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        // console.log(res.data);
        setSingleTransferList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Row style={{ marginTop: "5px", padding: "15px" }}>
        <Row className="mt-3 ">
          <Col lg={5} className="p-1 ps-2">
            <Input
              name="search"
              type="text"
              placeholder="Search by Batc ID, Payment ID, Beneficiary name"
            />
          </Col>
          <Col className="p-1">
            <Input name="date" type="date" />
          </Col>
          <Col className="p-1">
            <Input name="date2" type="date" />
          </Col>

          <Col>
            <Button
              style={{
                backgroundColor: "#a2751a",
                border: "none",
                marginRight: "10px",
              }}
            >
              <FaSearch />{" "}
            </Button>
            <span style={{ color: "#0149AD", marginRight: "10px" }}>
              {" "}
              clear
            </span>
            <Button
              style={{
                backgroundColor: "#0149AD",
                borderRadius: "50%",
                borderColor: "#0149AD",
                marginLeft: "8px",
              }}
            >
              <AiOutlineArrowDown />{" "}
            </Button>
          </Col>
        </Row>

        <Table
          className="no-wrap mt-3 align-middle border-top"
          responsive
          borderless
        >
          <thead className="fs-6">
            <tr>
              <th>Payment Id</th>
              <th>Request Date</th>
              <th>Last Updation Date</th>
              <th>From Account Number</th>
              <th>Beneficiary Name</th>
              <th>Beneficiary Bank</th>
              <th>Beneficiary A/C Number </th>
              <th>UTR </th>
              <th>Amount</th>
              <th>Status </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {singleTransferList?.length > 0 &&
              singleTransferList.map((item, index) => {
                return (
                  <tr key={index} className="border-top" style={{fontSize:'12px'}}>
                    <td>{item.payeeId}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.updatedAt}</td>
                    <td>{item.accountId}</td>
                    <td>{item.beneficiaryName}</td>
                    <td>{item.beneficiaryBankName}</td>
                    <td>{item.beneficiaryAccNumber}</td>
                    <td>{item.utr}</td>
                    <td>{item.amount}</td>
                    <td>{item.status}</td>
                    <td>{item.action}</td>
                  </tr>
                );
              })}

              {singleTransferList?.length === 0 && <h4>No Data Available</h4>}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default SingleTransferTab;
