
import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaWallet } from "react-icons/fa";
import { TiDownload } from "react-icons/ti";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
// import "./Admin.css";
import axios from "axios";

import { useNavigate, Link } from "react-router-dom";

import { FaTrashAlt, FaEdit } from "react-icons/fa"


// import { AccountListData } from "./AccountListData";
// import CustomPagination from "../Pagination/CustomPagination";
const QRCode = () => {
    let navigate = useNavigate();
    let PageSize = 10;

    useEffect(() => {
        getAccountList('');
    }, []);


    const [accountList, setAccountList] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('')



    const getAccountList = (searchData) => {

        if (searchData) {
            var searchparam = '?page=' + currentPage + '&_project=' + searchData
        } else {
            searchparam = '?page=' + currentPage
        }
        let url = process.env.REACT_APP_BASEURL + "/api/v1/account" + searchparam;
        const getlocalStorage = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                // "Accept" : "plain/text",
                Authorization: "Bearer " + getlocalStorage.token,
                "x-api-key": process.env.REACT_APP_X_API_KEY,
            },
        };
        axios
            .get(url, config)
            .then((res) => {
                if (res) {
                    console.log("RESSSSSSS", JSON.stringify(res));
                    // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
                }
            })
            .catch((error) => {
                // const getErrRes = errorHandler(error);
                // if (getErrRes === 401) {
                //   toast.error("Something went wrong.", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                //     theme: "colored",
                //   });
                //   navigate("/");
                // } else {
                //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                //     theme: "colored",
                //   });
                // }
            });
    };

    useMemo(() => {
        getAccountList(searchValue)
        // const firstPageIndex = (currentPage - 1) * PageSize;
        // const lastPageIndex = firstPageIndex + PageSize;
        // // console.log("firstPageIndex",firstPageIndex);
        // // console.log("PageSize",PageSize);
        // // console.log("lastPageIndex",lastPageIndex);
        // return accountList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);



    const handlePageClick = () => {
        console.log("DATA>>>> CALL >>> handlePageClick");
    }

    const onSearch = () => {
        if (searchValue) {
            getAccountList(searchValue)
        }
    }

    return (
        <>

            <Row style={{ padding: '15px' }}>
                <Col lg={12}>

                    <Row className="mt-3 ">
                        <Col className="d-flex justify-content-center fs-3" >
                            Coming Soon...


                        </Col>

                    </Row>


                    {/* {accountList?.length > 10 &&
                             <CustomPagination
                             className="pagination-bar"
                             currentPage={currentPage}
                             totalCount={accountList.length}
                             pageSize={PageSize}
                             onPageChange={page => setCurrentPage(page)}
                           />
                    } */}

                </Col>
            </Row>
        </>
    );
};

export default QRCode;

