import React, {  useState } from "react";
import { Col, Row, Button, Input } from "reactstrap";
import Card from "react-bootstrap/Card";
import { FiSend } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import Excel from "../../../assets/images/logos/excle_log.png";
import "./transfer.css";
import { FaFileUpload } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { MdDelete } from "react-icons/md"
// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';

function BulkTransfer() {
  let [openModal, setopenModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const delectFile =(id)=>{
    const getIndex = fileList.map(el=> el.id).indexOf(id)
    const data = [...fileList]
    if(getIndex !== -1){
      data.splice(getIndex, 1)
      setFileList(data);
    }}
  const handleFileChange = (e) => {
    e.preventDefault()
    if (e.target.files) {

      const createList = [...fileList , {id : fileList.length, file : e.target.files[0] ,name :  e.target.files[0].name}]
      console.log("createList",createList);
      setFileList(createList);
    }
  };
  let [fileName,setFileName]=useState('')

  return (
    <>
    
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h2 className="text-white">
            {" "}
            <FiSend /> Bulk Transfer
          </h2>
          <span className="text-white">Create a new Bulk Transfer Entry </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header  className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                <div style={{ marginLeft: '20px' }}> Bulk Transfer</div>
                <div style={{ marginRight: '20px' }}><BsThreeDotsVertical /></div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3">
                <Col>
                  <label>Select Account Number</label>
                  <Form.Select className="mt-2">
                    <option>Enter Account name</option>
                    <option disabled>No Items Found</option>
                  </Form.Select>
                </Col>
                <Col>
                  <label>Batch Name</label>
                  <Input
                    name="batch_name"
                    type="text"
                    placeholder="Batch Name"
                    className="mt-2"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button
                    onClick={() => {
                      setopenModal(true);
                    }}
                    style={{ background: "#23528b", border: "none" }}
                  >
                    Add Supporting Documents
                  </Button>
                  {openModal && (
                    <>
                      <Modal
                      show={true}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <div className="text-center h3 mt-4 fs-bolder">Add Doc</div>
                        <Modal.Body>
                          <div className="container">

                            {
                              
                               fileList.length> 0 && fileList.map((el, index)=>{

                                return(
                                  <div key={index} className="row m-2 mb-3">
                                  <div className="col-11">
                                  {/* <Input
                                    readOnly
                                    type="text"
                                    value={el.name}
                                    placeholder="Doc Name"
                                    className="py-2"
                                  /> */}
                                  <p> {el.name }</p>
                                  </div>
                                  <div className="col-1 d-flex justify-contant-center">
                                    <Button onClick={()=>{delectFile(el.id)}} className="btn btn-danger"><MdDelete/></Button>
                                  </div>
                                </div>
                                )
                              })

                       
                            }

                            {fileList.length === 0 && <h5 className="justify-content-center ms-5 p-5">No Documents Uploaded</h5>}
                          </div>
                        </Modal.Body>
     
                        <div className="row m-3">
                          <div className="col text-end">
                           <label htmlFor="bulk-file-upload" className="custom-file-upload text-center p-2 rounded-2 bg-info">
                        Upload File <FaFileUpload/>
                      </label>
                           <input style={{display:'none'}} id="bulk-file-upload" type="file" onChange={handleFileChange}/>
                          </div>
                          <div className="col text-start">
                           <Button onClick={() => {setopenModal(false)}} className="py-2 px-4 btn btn-dark">Close</Button>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )}
                </Col>
              </Row>
              <div className="d-flex justify-content-between mt-5">
                <div>
                  Enter The requird details in the template and upload (.XLS,
                  .XLSX, .CSV)
                </div>
                <div className="pe-5">
                  Download Template <img src={Excel} style={{ height: '28px', width: '28px' }} alt="title"></img>
                </div>
              </div>
              <Row className="mt-3 py-5 p-3">
                <label htmlFor="file-upload" className="custom-file-upload text-center "
                  style={{
                    border: "1px solid #ccc",
                    display: "inline-block",
                    padding: " 26px",
                    boxShadow: "0px 1px 16px 1px #00bcd459", cursor: " pointer"
                  }}>
                  Upload File <FaFileUpload />
                  <input id="file-upload" type="file" style={{ display: 'none' }} onChange={(e) => { setFileName(e.target.files[0].name) }} />
                  <br />
                  <span id="imageName" style={{ color: " green" }}>{fileName}</span>
                </label>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default BulkTransfer;
