import React from 'react'
import { Col, Row, Table, Button } from "reactstrap";
const AccountDocument = () => {
    return (
        <div>
            <Row style={{  padding: '15px', }} >
                <div>Document List</div>
                <Table className="no-wrap mt-4 align-middle border-top" responsive borderless>
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Payee Name</th>
                            <th>Payee Email</th>
                            <th>Share(0%) </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-top">
                            <td>1</td>
                            <td>Renu</td>
                            <td>renu@gmail</td>
                            <td>50%</td>
                            <td>True</td>
                        </tr>
                    </tbody>
                </Table>
                <Col>
                    <Button className="btn mt-1 header-color" style={{  borderColor: '#0149AD' }}>
                        Next
                    </Button>
                </Col>


            </Row>
        </div>
    )
}

export default AccountDocument
