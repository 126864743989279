import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsArrowsAngleContract } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FundDepositListData } from "./mokeData/fundDepositListData";
import CustomPagination from "../Pagination/CustomPagination";

const FundDeposit = () => {
  let navigate = useNavigate();
  let PageSize = 10;

  let [fundDeposit, setFundDeposit] = useState(FundDepositListData());

  useEffect(() => {
    getFundDepositList();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  let [filterParams, setFilterParams] = useState({
    dateFrom: "",
    dateTo: "",
    s: "",
    accountId: "",
  });
  const onSearch = () => {
    if (filterParams) {
      getFundDepositList();
    }
  };

  const getFundDepositList = () => {
    let url =
      process.env.REACT_APP_BASEURL +
      "/api/v1/transaction?page=" +
      currentPage +
      "&s=" +
      filterParams.s +
      "&dateFrom=" +
      filterParams.dateFrom +
      "&dateTo=" +
      filterParams.dateTo;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        // console.log(res.data);
        setFundDeposit(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useMemo(() => {
    getFundDepositList();
  }, [currentPage]);
  return (
    <>
      <Row>
        <Col className="header-color"
          style={{         
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            <BsArrowsAngleContract /> Funds Deposit Report{" "}
          </h3>
          <span className="text-white">Inward Funds Deposit</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header className="subheader-color" style={{ backgroundColor: "", padding: "20px" }}>
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD" }}
              >
                <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  {" "}
                  Deposit List
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3 p-0">
                <Col lg={5} className="p-1 ps-2">
                  <Input
                    name="search"
                    type="text"
                    value={filterParams.s}
                    onChange={(e) => {
                      setFilterParams({ ...filterParams, s: e.target.value });
                    }}
                    placeholder="Search by Virtual ID, Transaction ID, Remitter name"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateFrom"
                    value={filterParams.dateFrom}
                    onChange={(e) => {
                      setFilterParams({
                        ...filterParams,
                        dateFrom: e.target.value,
                      });
                    }}
                    type="date"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateTo"
                    value={filterParams.dateTo}
                    onChange={(e) => {
                      setFilterParams({
                        ...filterParams,
                        dateTo: e.target.value,
                      });
                    }}
                    type="date"
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "10px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    onClick={() => {
                      setFilterParams({
                        dateFrom: "",
                        dateTo: "",
                        s: "",
                        accountId: "",
                      });
                      getFundDepositList();
                    }}
                    style={{ color: "#0149AD", marginRight: "10px" }}
                  >
                    {" "}
                    clear
                  </span>
                  <Button
                    style={{
                      backgroundColor: "#0149AD",
                      borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    <AiOutlineArrowDown />{" "}
                  </Button>
                </Col>
              </Row>

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Transaction Id</th>
                    <th>Account Number</th>
                    <th>Amount</th>
                    <th>Remitter Name</th>
                    <th>Remitter Account</th>
                    <th>Remitter IFSC </th>
                    <th>Deposit Date </th>
                  </tr>
                </thead>
                <tbody>
                  {fundDeposit?.length > 0 &&
                    fundDeposit.map((item, index) => {
                      return (
                        <tr key={index} className="border-top">
                          <td>{item.transactionId}</td>
                          <td>{item.accountId}</td>
                          <td>{item.amount}</td>
                          <td>{item.remitterName}</td>
                          <td>{item.remitterAccNumber}</td>
                          <td>{item.remitterIFSCCode}</td>
                          <td>{item.date}</td>
                        </tr>
                      );
                    })}

                  {fundDeposit?.length === 0 && <h4>No Data Available</h4>}
                </tbody>
              </Table>
              <CustomPagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={fundDeposit.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FundDeposit;
