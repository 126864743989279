import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CiMobile4 } from "react-icons/ci"
import { Col, Row } from "reactstrap";
import Card from 'react-bootstrap/Card';
import Description from "./Description";
import "./Account.css"
import LinkPayee from "./LinkPayee";
import AccountDocument from "./AccountDoc";

const AccountTabs = () => {
    const [key, setKey] = useState('description');
    const [query, setQuery] = useState();
    useEffect(() => {
        if(query){
            console.log("AJSagjs " + query);
            setKey("linkpayee")
        }
    }, [query])

    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}><CiMobile4/> Add New Account</h3>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                    <Card.Body  style={{  padding: '0px' }} >
                            <Tabs defaultActiveKey="description" id="uncontrolled-tab-example"
                            className="subheader-color"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    style={{marginTop:'-15px',}}>
                                <Tab eventKey="description" title="Description"><Description onQuery={setQuery}/></Tab>
                                <Tab eventKey="linkpayee" title="Link Payee"><LinkPayee query={query}/></Tab>
                                <Tab eventKey="documents" title="Documents" ><AccountDocument/></Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
          
        </>
    );
}

export default AccountTabs;