
import React, { useState,useEffect,useMemo } from "react";
import { Col, Row, Button,Table, Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import Card from 'react-bootstrap/Card';
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { PayeesListData } from "./PayeesListData";
import CustomPagination from "../Pagination/CustomPagination";
const PayeesList = () => {
  let PageSize = 10;
  let navigate = useNavigate()

  useEffect(() => {
    getPayeeList('')
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue ]= useState('')
  const [payeeList, setPayyeList] = useState(PayeesListData())

  const onSearch = ()=>{
    if(searchValue){
      getPayeeList(searchValue)
    }
  }

  const getPayeeList = (searchParameters) => {

    if(searchParameters){
      var parmas = '?page='+currentPage+ '&_project='+searchParameters
    }else{
      parmas ='?page='+currentPage
    }


    let url = process.env.REACT_APP_BASEURL + "/api/v1/payee" + parmas
    const getlocalStorage = (localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY))
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        'Authorization': 'Bearer ' + getlocalStorage.token,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    console.log("config", config);
    axios.get(url, config)
      .then(res => {
        if (res) {
          console.log("RESSSSSSS", JSON.stringify(res));
        }
      }).catch(error => {
        console.log("GGGGGGGGGGG");

        // const getErrRes = errorHandler(error)
        // if (getErrRes === 401) {
        //   toast.error('Something went wrong.', {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        //   navigate("/");
        // } else {
        //   toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });

        // }
      })
  }

  useMemo(() => {
    getPayeeList(searchValue)
  // const firstPageIndex = (currentPage - 1) * PageSize;
  // const lastPageIndex = firstPageIndex + PageSize;
  // // console.log("firstPageIndex",firstPageIndex);
  // // console.log("PageSize",PageSize);
  // // console.log("lastPageIndex",lastPageIndex);
  // return accountList.slice(firstPageIndex, lastPageIndex);
}, [currentPage]);


  return (
    <>
      <Row>
        <Col className="header-color" style={{  padding: '20px 20px 100px 20px', }}>
          <h3 className="text-white" style={{ fontWeight: "bold" }}>Payee</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: '-50px' }} >
        <Col lg={12}>
          <Card>
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{ marginLeft: '20px', fontWeight: "bold" }}> Payee List</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3" >
                <Col xs="6">
                  <Input name="search" type="text" placeholder="Search by Payee Name" value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value) }}             
                                          
                  
                  />
                  
                </Col>
                <Col xs="6">
                  <Button   onClick={()=> onSearch()} style={{ backgroundColor: '#a2751a', border: 'none', marginRight: '5px' }}><FaSearch /> </Button>
                  <span style={{ color: '#0149AD', marginRight: '5px' }}
                    onClick={()=> {
                      setSearchValue('')
                      getPayeeList('')
                      }
                    }
                  > clear</span>
                  <Button className="header-color" style={{ borderRadius: '50%', borderColor: '#0149AD', marginLeft: '1px' }}><AiOutlineArrowDown /> </Button>
                </Col>
              </Row>
              <Table className="no-wrap mt-3 align-middle border-top" responsive borderless>
                <thead>
                  <tr>
                    <th>Payee id</th>
                    <th>Account Holder</th>
                    <th>IFSC</th>
                    <th>Account Number</th>
                    <th>Bank</th>
                    <th>Addition Date </th>
                  </tr>
                </thead>
                <tbody>
                  {payeeList?.length &&
                    payeeList?.length > 0 &&
                    payeeList.map((item, index) => {
                      console.log(item)
                      return (
                        <tr className="border-top">
                          <td>{item.payeeId}</td>
                          <td>{item.accountHolder}</td>
                          <td>{item.ifsc}</td>
                          <td>{item.accountNumber}</td>
                          <td>{item.bankName}</td>
                          <td>{item.createdAt}</td>

                        </tr>
                      );
                    })}

                  {payeeList.length === 0 && <h4>NO DATA Available</h4>}
                </tbody>
              </Table>
              <CustomPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={payeeList.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PayeesList;
